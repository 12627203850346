<template>
	<div class="col-12">
		<div class="card card-outline card-navy">
			<div class="card-header">
				<h3 class="card-title">Lista de Pagos</h3>
				<div class="card-tools">
					<button @click="downloadFiles()" class="btn btn-sm btn-outline-secondary mr-1">
						<i class="fa-solid fa-box-archive"></i> Archivo de facturas
					</button>
					<button class="btn btn-outline-success btn-sm mr-2" if="authUser.organization_charts_id != 2"
						@click="exportData()">
						<i class="fa fa-file-excel"></i> Exportar
					</button>
				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-md-7 col-lg-8">
						<label>Buscar :</label>
						<input class="form-control" v-debounce:2s="list" type="search" v-model="search_all">
					</div>
					<div class="col-md-5 col-lg-4">
						<label>Rango de fechas :</label>
						<date-picker format="YYYY-MM-DD" valueType="format" :range="true"
							v-model="search.date"></date-picker>
					</div>
					<div class="col-md-4 mt-2" v-if="authUser.organization_charts_id != 2">
						<label>Empresa :</label>
						<select v-model="search.company_id" class="form-control">
							<option value="">Todos</option>
							<option v-for="(company, index) in catalogue.companies_buildings" :key="index"
								:value="company.id"> {{ company.name }}
							</option>
						</select>
					</div>
					<div class="col-md-4 mt-2">
						<label>Tipo :</label>
						<select type="text" class="form-control" v-model="search.type_id">
							<option value="">Todos</option>
							<option value="1">Presupuestos</option>
							<option value="2">Gasto recurrente</option>

						</select>
					</div>
					<div class="col-md-4 mt-2" v-if="authUser.organization_charts_id != 2">
						<label>Usuario :</label>
						<select type="text" class="form-control" v-model="search.user_id">
							<option value="">Todos</option>
							<option v-for="(user, index) in catalogue.users_profile" :value="user.id" :key="index">
								{{ user.name }} {{ user.last_name }}</option>
						</select>
					</div>
					<div class="col-md-4 mt-2">
						<label>Categoría :</label>
						<select type="text" class="form-control" v-model="search.concept_id">
							<option value="">Todos</option>
							<option v-for="(concept, index) in catalogue.concepts" :value="concept.id" :key="index">
								{{ concept.name }}</option>
						</select>
					</div>
					<div class="col-md-4 mt-2">
						<label>Tipo de saldo</label>
						<select type="text" class="form-control" v-model="search.type_payment_id">
							<option value="">Todos</option>
							<option v-for="(type_payment, index_type_payment) in catalogue.type_payments"
								:value="type_payment.id" :key="index_type_payment">{{
									type_payment.name }}</option>
						</select>
					</div>
					<div class="col-md-4 mt-2">
						<label>Tipo de factura :</label>
						<select class="form-control" v-model="search.biill_type_id">
							<option value="">Todos</option>
							<option v-for="(type, index) in catalogue.bills_types" :value="type.id" :key="index">{{
								type.name }}</option>
						</select>
					</div>

					<div class="col-md-4 mt-2" v-if="authUser.organization_charts_id != 2">
						<label>Razón Social :</label>
						<select class="form-control" v-model="search.billing_data_id">
							<option value="">Todos</option>
							<option v-for="(type, index) in catalogue.all_billing_data" :value="type.id" :key="index">
								{{ type.business_name }} - {{ type.rfc }}
							</option>
						</select>
					</div>
					<div class="col-md-4 mt-3">
						<button class="btn mt-4 btn-block btn-outline-secondary"
							:class="search.complement == 1 ? 'active' : ''"
							@click=" search.complement = search.complement == 1 ? '' : '1'">
							<i class="fa-solid fa-circle-info fa-lg text-info"></i> - Sin complemento de pago
						</button>
					</div>
					<div class="col-md-4 mt-3">
						<button class="btn mt-4 btn-block btn-outline-secondary"
							:class="search.pay_roll == 1 ? 'active' : ''"
							@click=" search.pay_roll = search.pay_roll == 1 ? '' : '1'">
							<i class="fa-solid fa-file-invoice fa-lg text-info"></i> - Nominas
						</button>
					</div>
				</div>
				<br>
				<div class="table-responsive">
					<table class="table table-bordered text-sm text-center">
						<thead>
							<tr>
								<th>ID</th>
								<th style="width: 45%;">Datos</th>
								<th style="width: 140px;">Monto</th>
								<th style="width: 140px;">Por pagar</th>
								<th style="width: 140px;">Pagado</th>
								<th>Fechas de pagos</th>
								<th class="text-center">Opciones</th>
							</tr>
						</thead>
						<tbody v-if="load">
							<tr>
								<td align="center" colspan="9">
									<i class="fa-solid fa-circle-notch fa-spin fa-3x mb-1"></i>
									<h5>Cargando</h5>
								</td>
							</tr>
						</tbody>
						<tbody v-else-if="pays.total > 0">
							<tr :class="buy.id == selected ? 'selected' : ''" v-for="(buy, index) in pays.data"
								:key="index">
								<td>
									{{ buy.id }}

									<div class="d-inline mt-3" v-if="buy.bill">
										<div class="d-inline" v-if="showItem(buy)">
											<i v-if="!buy.bill.complement_xml"
												class="fa-solid fa-circle-info text-info fa-xl  fa-fade"></i>
										</div>
									</div>

								</td>
								<td class="text-left">
									<span v-if="buy.payroll_id">
										Nomina
										<hr>
										<strong>Fecha : </strong>
										{{ buy.pay_roll.date | toDateTime }}<br>
										<strong>Compañía : </strong>
										{{ buy.pay_roll.company.name }}<br>
										<strong>Fecha inicio : </strong>
										{{ buy.pay_roll.date_start | toDateTime }}<br>
										<strong>Fecha fin : </strong>
										{{ buy.pay_roll.date_end | toDateTime }}<br>
										<strong>Dispersión : </strong>
										{{ buy.pay_roll.dispersion == 1 ? 'Si' : 'No' }}<br>
										<strong>Monto : </strong>
										{{ buy.pay_roll.amount | toCurrency }}<br>
									</span>
									<!-- otros -->
									<span v-else>
										<!-- Tipo -->
										<span v-if="buy.type_id == 1"> Presupuesto </span>
										<span v-else> Gasto recurrente </span>
										<br>

										<!-- Proveedor -->
										<strong>Usuario : </strong>
										<span v-if="buy.provider"> {{ buy.provider.name }} {{ buy.provider.last_name
										}}</span>
										<span v-else>Sin usuario</span>
										<br>

										<!-- Concepto -->
										<strong>Concepto : </strong>
										<span v-if="buy.bill"> {{ buy.bill.name }}</span>
										<span v-else>Sin Concepto</span>
										<br>

										<!-- Empresa -->
										<strong>Empresa: </strong>
										<span v-if="buy.bill">
											<span v-if="buy.bill.company">
												{{ buy.bill.company.name }}
											</span>
										</span>
										<span v-else>Sin Empresa</span>
										<br>
										<br>

										<!-- Tipo -->
										<strong>Tipo de movimiento : </strong>
										<span v-if="buy.type_payment"> {{ buy.type_payment.name }}</span>
										<span v-else>Sin tipo</span>
										<br>

										<!-- Categoría -->
										<strong>Categoría : </strong>
										<span v-if="buy.concept"> {{ buy.concept.name }}</span>
										<span v-else>Sin categoría</span>
										<hr>
										<strong>Datos de facturación </strong>
										<br>

										<!-- Razón social -->
										<span v-if="buy.bill">
											<span v-if="buy.bill.user_bill">
												{{ buy.bill.user_bill.business_name }}
											</span>
											<span v-else>Sin Razón social</span>
										</span>
										<span v-else>Sin Razón social</span>
										<br>

										<!-- Folio Factura -->
										<strong>Folio : </strong>
										<span v-if="buy.bill">
											<span v-if="buy.bill.folio">
												{{ buy.bill.folio }}
											</span>
											<span v-else>Sin Folio</span>
										</span>
										<span v-else>Sin Folio</span>
										<br>

										<!-- RFC -->
										<span v-if="buy.bill">
											<span v-if="buy.bill.user_bill">
												{{ buy.bill.user_bill.rfc }} |
												<a class="btn btn-info btn-sm" target="_blank"
													v-if="buy.bill.user_bill.cedula"
													:href="`${globalUrlFiles}${buy.bill.user_bill.cedula}`">
													<i class="fa-regular fa-file-pdf"></i> Ver cédula fiscal
												</a>
												<small v-else class="btn_now badge badge-secondary"> Sin caratula
													fiscal</small>

											</span>
											<span v-else>Sin RFC</span>
										</span>
										<span v-else>Sin RFC</span>
										<br>

										<strong>Aprobado por :</strong><br>
										<span v-if="buy.bill">
											<span v-if="buy.bill.provider">
												{{ buy.bill.provider.name }} {{ buy.bill.provider.last_name }}
											</span>
											<span v-else>S/D</span>
										</span>
										<span v-else>S/D</span>
										<br>

										<!-- complemento de pago -->
										<div class="d-inline mt-3" v-if="buy.bill">
											<div class="row" v-if="buy.bill.check && buy.bill.biill_type_id == 2">
												<div class="col-12">
													<strong>Complemento de pago:</strong><br>
													<a class="btn btn-info btn-sm m-1" v-if="buy.bill.complement_file"
														@click="downloadFile('complement_pdf', buy.bill.id)">
														<i class="fa-regular fa-file-pdf"></i> Complemento PDF
													</a>
													<a class="btn btn-info btn-sm m-1" v-if="buy.bill.complement_xml"
														@click="downloadFile('complement_xml', buy.bill.id)">
														<i class="fa-regular fa-file-pdf"></i> Complemento XML
													</a>
													<span v-else> Sin complemento de pago </span>

												</div>
											</div>
										</div>

										<hr>
										<strong>Cuenta Bancaria </strong>
										<br>






										<!-- Banco -->
										<span v-if="buy.bill">
											<span v-if="buy.bill.bank_account_providers">
												{{ buy.bill.bank_account_providers.bank.name }}
											</span>
											<span v-else>Sin Banco</span>
										</span>
										<span v-else>Sin Banco</span>
										<br>

										<!-- Cuenta -->
										<span v-if="buy.bill">
											<span v-if="buy.bill.bank_account_providers">
												{{ buy.bill.bank_account_providers.clue_number }} |
												<a class="btn btn-info btn-sm" target="_blank"
													v-if="buy.bill.bank_account_providers.file"
													:href="`${globalUrlFiles}${buy.bill.bank_account_providers.file}`">
													<i class="fa-regular fa-file-pdf"></i> Ver caratula de banco
												</a>
												<small v-else class="btn_now badge badge-secondary"> Sin caratula de
													banco</small>

											</span>
											<span v-else>Sin Cuenta</span>
										</span>
										<span v-else>Sin Cuenta</span>
										<br>

										<!-- Concepto -->
										<strong>Concepto : </strong>
										<span v-if="buy.bill"> {{ buy.bill.name }}</span>
										<span v-else>Sin Concepto</span>
										<br>

										<!-- Moneda -->
										<strong>Moneda : </strong>
										<span v-if="buy.bill">
											<span v-if="buy.bill.currency_id"> {{ buy.bill.currency.name }}</span>
											<span v-else>S/D</span>
										</span>
										<span v-else>S/D</span>
										<br>

										<!-- Descripción -->
										<strong>Descripción : </strong>
										<span v-if="buy.bill"> {{ buy.bill.description }}</span>
										<span v-else>Sin Descripción</span>
										<br>

									</span>
								</td>
								<td class="text-right">
									{{ (buy.amount + buy.credit) | toCurrency }}
								</td>
								<td class="text-right">
									{{ buy.amount_payable | toCurrency }}
								</td>
								<td class="text-right">
									{{ buy.sum_pays | toCurrency }}
								</td>

								<td>
									<span v-if="buy.bill">
										<span v-if="buy.bill.check">
											<span v-if="buy.bill.check.pay">
												<span v-for="(pay, index_pay) in buy.bill.check.pay" :key="index_pay">
													{{ pay.date | toDateTime }}
												</span>
											</span>
										</span>
									</span>
									<span v-else>
										S/F
									</span>

								</td>
								<!-- <td>
									<span v-if="buy.pay"> {{ buy.pay.user_approved.name }} {{
										buy.pay.user_approved.last_name }}</span>
									<span v-else>Sin Usuario</span>
								</td>
								<td>
									<span v-if="buy.pay"> {{ buy.pay.authorization_date | toDateTime }} </span>
									<span v-else>Sin Fecha</span>
								</td> -->
								<td class="text-center">
									<button v-if="buy.bill" @click="formDataBill(buy.bill)"
										class="btn btn-outline-secondary m-1 btn-sm">
										<i class="fa-solid fa-receipt mr-1"></i> Ver cheque
									</button>


									<button v-if="buy.type_id == 1" @click="showBudget(buy.budget.id)" type="button"
										class="btn btn-sm  m-1 btn-outline-light ">
										<i class="fa-solid fa-coins mr-1"></i>Ver presupuesto
									</button>

									<div class="d-inline" v-if="buy.buy">

										<button v-if="buy.type_id == 2 && buy.buy.payment_period_id !== 5"
											@click="viewPay(buy.buy_id)" type="button"
											class="btn btn-sm  m-1 btn-outline-light ">
											<i class="fa-solid fa-basket-shopping mr-1"></i>Ver compra recurrente
										</button>

										<button v-if="buy.type_id == 2 && buy.buy.buy_id"
											@click="viewPay(buy.buy.buy_id)" type="button"
											class="btn btn-sm  m-1 btn-outline-light ">
											<i class="fa-solid fa-basket-shopping mr-1"></i>Ver compra recurrente
										</button>

									</div>
									<!-- <button @click="formData(buy)" v-can="'edit_pays'"
										class="btn btn-outline-primary m-1 btn-sm">
										<i class="fas fa-edit "> </i> Editar
									</button> -->


									<!-- <button type="button" v-if="buy.pay.length == 0" @click="deleted(buy.id)" v-can="'deleted_checks'"
										class="btn-outline-danger btn m-1 btn-sm ">
										<i class="fa fa-trash"></i> Eliminar
									</button> -->
									<!-- <button @click="formDataPays(buy, buy.pay)" v-can="'add_pays'" class="btn m-1 btn-sm btn-outline-warning">
										<i class="fa-solid fa-hand-holding-dollar mr-1"> </i>
										<span>Ver pago</span>
									</button> -->
									<button @click="formListPays(buy)" class="btn m-1 btn-sm btn-outline-warning">
										<i class="fa-solid fa-hand-holding-dollar mr-1"> </i>
										<span>Ver pago</span>
									</button>

									<div class="d-inline" v-if="buy.bill">
										<button type="button" v-if="buy.type_payment_id == 5 && buy.signature"
											@click="downloadRecibe(buy.id)"
											class="btn m-1 btn-outline-info btn btn-sm ">
											<i class="fa-solid fa-file-pdf"></i> Recibo de pago
										</button>

										<button type="button" v-can="'approve_pays'"
											v-if="buy.type_payment_id == 5 && !buy.signature" @click="addSignature(buy)"
											class="btn m-1 btn-outline-secondary btn btn-sm ">
											<i class="fa-solid fa-signature"></i> Firmar pago
										</button>


										<a v-if="buy.bill.file" :href="`${globalUrlFiles}${buy.bill.file}`"
											target="_blank" class="btn m-1 btn-outline-secondary btn-sm">
											<i class="fas fa-file-pdf "> </i> Archivo PDF
										</a>
										<button v-if="buy.bill.xml" class="btn m-1 btn-outline-secondary btn-sm"
											@click="downloadFile('xml', buy.bill.id)">
											<i class="fas fa-file "> </i> Archivo XML
										</button>

										<div class="d-inline" v-if="buy.bill">
											<div class="d-inline" v-if="buy.bill.check && buy.bill.biill_type_id == 2">
												<button type="button" v-if="buy.bill.check.pay"
													@click="newPaymentPlugin(buy.bill)" class="btn m-1 btn-sm "
													:class="buy.bill.complement_xml ? 'btn-outline-success' : 'btn-outline-warning'">
													<i class="fa-solid fa-comments-dollar"></i>
													<span v-if="buy.bill.complement_xml"> Ver complemento de pago
													</span>
													<span v-else> Agrega complemento de pago </span>
												</button>
											</div>
										</div>

										<div class="d-inline" v-if="buy.bill">
											<button type="button" v-if="buy.bill" @click="showChecks(buy.bill)"
												class="btn m-1 btn-outline-success btn btn-sm ">
												<i class="fa-solid fa-receipt"></i> Ver cheques de la factura
											</button>

											<button @click="newCredit(buy.bill, buy.bill.credit)" v-if="buy.bill.credit"
												class="btn m-1 btn-sm btn-outline-info">
												<i class="fa-solid fa-comment-dollar mr-1"></i>Ver nota de crédito
											</button>
										</div>

										<div class="d-inline" v-if="buy.bill">
											<button type="button" v-if="buy.bill.pety_cash"
												@click="showPettyCash(buy.bill.pety_cash)"
												class="btn m-1 btn-outline-success btn btn-sm ">
												<i class="fa-solid fa-cash-register"></i> Ver movimiento
											</button>
										</div>

									</div>

									<div class="d-inline" v-if="buy.bill">
										<button v-if="buy.bill.biill_type_id == 4" v-can="'edit_bill'"
											@click="formDataBill(buy.bill, true)"
											class="btn btn-outline-info m-1 btn-sm">
											<i class="fa-solid fa-edit mr-1"></i> Editar factura
										</button>
									</div>

								</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td align="center" colspan="9">Sin resultados.</td>
							</tr>
						</tbody>
						<tfoot v-if="pays.total > 0 && load == false">
							<tr class="table-secondary">
								<td colspan="2">
									Total :
								</td>
								<td class="text-right">
									{{ sumdata()[0] | toCurrency }}
								</td>
								<td class="text-right">
									{{ sumdata()[1] | toCurrency }}
								</td>
								<td class="text-right">
									{{ sumdata()[2] | toCurrency }}
								</td>
								<td colspan="4"></td>
							</tr>
						</tfoot>
					</table>
					<pagination v-if="pays" v-model="page" :records="pays.total" :per-page="pays.per_page"
						@paginate="list" />
				</div>
			</div>
		</div>

		<ModalFormComponent :prop_edit="false" />
		<ModalTableComponentPays :prop_edit="false" />
		<ModalFormComponentBill :prop_edit="editBill" />
		<ModalDownloadFiles />
		<ModalFormReceiptComponent />
		<ModalBuysTable />
		<ModalBudgetsTable />
		<ModalBuysRelationComponent />
		<ModalSignatureComponentVue />
		<ModalCredit :prop_edit="false" />
		<ModalChecksBillSummary />
		<ModalFormComponentBudgetsPetty :prop_edit="false" />


	</div>
</template>



<script>
import ModalCredit from '@/components/bill/ModalCreditComponent.vue';
import ModalDownloadFiles from '@/components/bill/ModalDownloadFilesComponent.vue';
import ModalFormReceiptComponent from '@/components/pays/ModalFormReceiptComponent.vue';

import ModalFormComponent from '@/components/checks/ModalFormComponent.vue';
import ModalTableComponentPays from '@/components/pays/ModalTableComponent.vue';
import ModalFormComponentBill from '@/components/bill/ModalFormComponent.vue';
import ModalSignatureComponentVue from '@/components/pays/ModalSignatureComponent.vue';

import ModalBuysTable from '@/components/buy/ModalTableComponent.vue'
import ModalBudgetsTable from '@/components/budgets/ModalTableComponent.vue'

import ModalBuysRelationComponent from '@/components/buy/ModalBuysRelationComponent.vue';
import ModalChecksBillSummary from '@/components/checks/ModalChecksBillSummaryComponent.vue'
import ModalFormComponentBudgetsPetty from '@/components/petty_cash/ModalFormPettyCashComponent.vue'

import { mapMutations, mapActions, mapGetters } from 'vuex';
import Pagination from 'vue-pagination-2';
import request from '@/services/request';
import Catalogue from '@/services/catalogue';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';


export default {
	name: "companies-table",
	components: {
		ModalFormComponent,
		ModalTableComponentPays,
		ModalFormComponentBill,
		ModalDownloadFiles,
		ModalFormReceiptComponent,
		Pagination,
		DatePicker,
		ModalBuysTable,
		ModalBudgetsTable,
		ModalBuysRelationComponent,
		ModalSignatureComponentVue,
		ModalCredit,
		ModalChecksBillSummary,
		ModalFormComponentBudgetsPetty
	},
	data() {
		return {
			name: '',
			editBill: '',
			pays: {},
			selected: '',
			search: {
				date: '',
				company_id: '',
				paid: 1,
				search: '',
				type_id: '',
				user_id: '',
				concept_id: '',
				biill_type_id: '',
				billing_data_id: '',
				type_payment_id: '',
				complement: '',
				pay_roll: '',
			},
			search_all: '',
			page: 1,
			catalogue: new Catalogue(),
			load: true,
		}
	},
	async mounted() {
		let id = this.$route.query.id;
		if (id) {
			this.search.complement = 1;
		} else {
			await this.list();
		}

		await this.catalogue.get_companies_buildings();
		await this.catalogue.get_users_profile(2);
		await this.catalogue.get_concepts();
		await this.catalogue.get_type_payments();
		await this.catalogue.get_bills_types();
		await this.catalogue.get_all_billing_data();
		this.setHeaderTitle('Gasto recurrente');
		await this.showSelected(id);

	},
	created() {
		Event.$on("event-load-checks", () => {
			this.list();
		});
		Event.$on("event-load-bills", () => {
			this.list();
		});
	},
	methods: {
		showPettyCash(petty = false) {
			Event.$emit('event-show-addPettyCash', petty);
		},
		showItem(item) {
			// Asegúrate de que la fecha se compare correctamente
			let dateCondition = new Date(item.date) >= new Date('2024-07-01');
			return item.bill.check && item.bill.biill_type_id == 2 && dateCondition;
		},
		showChecks(bill = {}) {
			Event.$emit('event-show-listChecksBillsSummary', bill);
		},
		newCredit(bill, credit) {
			Event.$emit('event-show-addBillCredit', bill, credit);
		},
		addSignature(bill = {}) {
			console.log(bill);
			Event.$emit('event-show-addSiganturePay', bill);
		},
		viewPay(id) {
			Event.$emit('event-show-BuysRelationID', id);
			// Event.$emit('event-load-buy', id);
		},
		showSelected(id = false) {
			if (id) {
				this.selected = id;
			} else {
				this.selected = '';
			}

		},
		newPaymentPlugin(bill = {}) {
			console.log(bill);
			Event.$emit('event-show-addPaymentPlugin', bill);
		},
		downloadFiles() {
			let filter = this.search;
			filter.type = 'pays';
			Event.$emit('event-show-downloadFiles', filter);
		},
		sumdata() {
			let sum1 = 0;
			let sum2 = 0;
			let sum3 = 0;
			if (this.pays) {
				this.pays.data.forEach(function (buy) {
					console.log(buy.amount + buy.credit);
					if (buy.amount) {
						sum1 += parseFloat(buy.amount);
					}
					if (buy.credit) {
						sum1 += parseFloat(buy.credit);
					}
					sum2 += parseFloat(buy.amount_payable);
					sum3 += parseFloat(buy.sum_pays);

				});
			}

			return [sum1, sum2, sum3];
		},
		downloadFile(type, id) {
			let url = this.globalUrlFiles + '/bills/download_file?id=' + id + '&type=' + type;
			window.open(url, '_blank');
		},
		showBudget(id) {
			Event.$emit('event-load-budget', id);
		},
		exportData() {
			let filter = this.globalUrlFiles + '/pays/export?' + new URLSearchParams(this.search).toString();
			filter += '&user_login=' + this.authUser.id;
			window.open(filter, '_blank');
		},
		cancelPays(id) {
			let vm = this;
			console.log(id);
			this.$swal({
				title: 'Motivos de Cancelación',
				input: 'text',
				icon: 'warning',
				inputAttributes: {
					autocapitalize: 'off'
				},
				showCancelButton: true,
				confirmButtonText: 'Guardar',
				showLoaderOnConfirm: true,
				preConfirm: async (message) => {
					console.log(message);
					let form = {
						id: id,
						message: message,
					};
					let _endpoint = '/cancel_pays';
					try {
						let { data } = await request.postMedia(_endpoint, form)
						if (data) {
							console.log(data);
							await vm.successNotification(data.message);
							await vm.list();
						}
					} catch (error) {
						vm.errors = {};
						if (error.response) {
							vm.errors = vm.errorNotification(error);
						}
					}
				},
				allowOutsideClick: () => !this.$swal.isLoading()
			}).then((result) => {
				if (result.isConfirmed) {
					console.log(result);
				}
			})

		},
		formData(edit = {}) {
			console.log(edit);
			Event.$emit('event-show-addChecks', edit);
		},
		formDataBill(dta = {}, edit = false) {
			console.log(dta);
			this.editBill = edit;
			Event.$emit('event-show-addBill', dta);
		},
		formDataPays(check, edit = {}) {
			Event.$emit('event-show-addPays', check, edit);
		},
		formListPays(check) {
			Event.$emit('event-show-listPays', check);
		},
		formDataPaysFast(check) {
			this.$swal({
				title: 'Pago rapido',
				text: 'Se aprobara el pago rapido',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Pagar',
				cancelButtonText: 'Cancelar'
			}).then(async (result) => {
				if (result.isConfirmed) {
					let form = {
						check_id: check.id,
						date: this.moment().format("YYYY-MM-DD"),
						amount: check.amount,
						description: 'Pago aprobado'
					};
					let _endpoint = '/pays';
					try {
						let { data } = await request.postMedia(_endpoint, form)
						if (data) {
							console.log(data);
							this.successNotification(data.message);
							this.list();
						}
					} catch (error) {
						this.errors = {};
						if (error.response) {
							this.errors = await this.errorNotification(error);
						}
					}
				}
			});
		},
		deleted: function (id) {
			let tit = 'Eliminar cheque';
			let txt = 'Seguro que quiere eliminar la cheque';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callDeleted(id);
				}
			});
		},
		approve: function (id) {
			let tit = 'Aprobar Presupuestos';
			let txt = 'Seguro que quiere aprobar el presupuestos';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then(async (result) => {
				if (result.isConfirmed) {
					let _endpoint = '/pays_approve';
					let form = {
						id: id
					};
					try {
						let response = await request.post(_endpoint, form).then(function (response) { return response; });
						this.list();
						this.successNotification(response.data.message);
					} catch (error) {
						this.errors = await this.errorNotification(error)
					}
				}
			});
		},
		async callDeleted(id) {
			let _endpoint = '/pays/' + id;
			try {
				let response = await request.destroy(_endpoint).then(function (response) { return response; });
				this.list();
				this.successNotification(response.data.message);
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		showImport() {
			this.$modal.show('example');
		},
		async list(page = this.page) {
			this.load = true;
			let service = request;
			let search = this.search;
			search.search = this.search_all;
			let _endpoint = '/pays';
			try {
				let { data } = await service.list(_endpoint, page, search)
				if (data) {
					console.log(data);
					this.pays = data;
					this.load = false;
					this.selected = '';
				}
			} catch (error) {
				console.log(error);
				this.load = false;
			}
		},
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		}),
		...mapActions({
			resetPassword: 'auth/resetPass',
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		}),
	},
	computed: {
		...mapGetters({
			'authUser': 'auth/user'
		})
	},
	watch: {
		'$route.query': {
			handler: function (params) {
				if (params) {
					this.showSelected(params.id);
				}
			},
			deep: true,
			immediate: true
		},
		'name': {
			handler: function (val) {
				if (val == '') {
					this.list();
				}
			},
			deep: true
		},
		"search": {
			handler: function () {
				this.list();
			},
			deep: true
		}
	}
}
</script>

<style scoped>
.pagination {
	margin-bottom: 0;
}

.bg-info {
	background-color: #061f3f;
}

.selected {
	color: #000;
	font-weight: bold;
	animation: blinkingText 4s infinite;
}

@keyframes blinkingText {
	0% {
		border: 3px solid #36a590;
	}

	25% {
		border: 3px solid #dee2e6;
	}

	50% {
		border: 3px solid #36a590;
	}

	75% {
		border: 3px solid #dee2e6;
	}

	100% {
		border: 3px solid #36a590;
	}
}
</style>
