<template>
	<div class="col-12">
		<div class="card card-outline card-secondary">
			<div class="card-header">
				<h3 class="card-title">Lista de Indivisos</h3>
				<div class="card-tools">                   
                    <button @click="downloadDepartments()" class="btn mr-1 btn-sm btn-outline-success">
                        <i class="fa fa-file-excel"></i> Excel de departamentos
                    </button>                  
                </div>				
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-md-4">
						<label>Empresa: </label>
						<select v-model="search.company_id" class="form-control">
							<option value="">Todos</option>
							<option v-for="(company, index) in catalogue.companies_buildings" :key="index"
								:value="company.id"> {{ company.name }}
							</option>
						</select>
					</div>
					<div class="col-md-4">
						<label>Cliente: </label>
						<select class="form-control" v-model="search.user_id">
							<option value="">Todos</option>
							<option v-for="(user, index) in catalogue.users_profile" :value="user.id" :key="index">
								{{ user.name }} {{ user.last_name }}</option>
						</select>
					</div>
					<div class="col-md-4">
						<label>Estatus: </label>
						<select class="form-control" v-model="search.status">
							<option value="">Todas</option>
							<option value="1">Ocupados</option>
							<option value="2">Libres</option>
						</select>
					</div>
				</div>
				<br>
				<div class="row">
					<div class="col-md-12">
						<div class="table-responsive">
							<table class="table tbl-pays table-bordered text-center text-sm">
								<thead>
									<tr>
										<th>ID</th>
										<th>EDIFICIO</th>
										<th>LOCAL / DEPTO</th>
										<th v-if="authUser.organization_charts_id != 17">TIPO</th>
										<th>TIPO</th>
										<th>M2</th>
										<th>ESTATUS</th>
										<th>CLIENTE</th>
										<th>OPCIONES</th>
									</tr>
								</thead>
								<tbody v-if="load">
									<tr>
										<td :colspan=" authUser.organization_charts_id != 17 ? '9' : '8'" class="p-0">
											<div class="col-md-12 mt-5 mb-5 text-center">
												<i class="fa-solid fa-circle-notch fa-spin fa-3x mb-1"></i>
												<h5>Cargando</h5>
											</div>
										</td>
									</tr>
								</tbody>
								<tbody v-if="departments.length > 0">

									<tr v-for="(dep, index) in departments" :key="index" :class="dep.current_tenant ? 'bg-success' : ''">
										<td>{{ dep.id }}</td>
										<td>{{ dep.building.name }}</td>
										<td>{{ dep.number }} / {{ dep.building.level }}</td>
										<td v-if="authUser.organization_charts_id != 17">
											<button class="btn btn-outline-primary btn-sm" @click="showContracts(dep)">
												<i class="fa-solid fa-file-lines"></i>
											</button>
										</td>
										<td>
											<span v-if="dep.type_department">
												{{ dep.type_department.name }}
											</span>
											<span v-else>S/N</span>
										</td>
										<td>
											{{ dep.square_meter }}
										</td>
										<td>
											{{ dep.current_tenant ? 'Ocupado' : '' }}
										</td>
										<td>
											<span v-if="dep.current_tenant?.user">
												{{ dep.current_tenant?.user.name }}
												<span
													v-if="dep.current_tenant?.user.last_name && dep.current_tenant?.user.last_name != null">
													{{ dep.current_tenant?.user.last_name }}
												</span>
											</span>
										</td>
										<td>
											<span v-if="dep.file">
												<a :href="`${globalUrlFiles}${dep.file}`"
													v-if="file != ''" target="_blank" class="btn btn-secondary btn-sm">
													<i class="fa-regular fa-file"></i> Planos
												</a>
											</span>
											<button class="btn btn-primary btn-sm m-1" @click="editDepartment(dep)">
												<i class="fa-solid fa-edit"></i> Editar
											</button>
										</td>
									</tr>

								</tbody>
								<tbody v-if="!load">
									<tr class="p-0" v-if="departments.length == 0">
										<td align="center" :colspan=" authUser.organization_charts_id != 17 ? '9' : '8'">Sin resultados.</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>

				</div>
			</div>
		</div>
		<ModalContractDepartament />
		<ModalDepartment />
	</div>
</template>

<script>
import ModalContractDepartament from '@/components/contracts/ModalTableContractsAllComponent.vue';
import ModalDepartment from '@/components/departments/ModalFormComponent.vue';

import { mapMutations, mapGetters, mapActions } from 'vuex';
import Catalogue from '@/services/catalogue';

import request from '@/services/request'
export default {
	name: "buildins-table",
	components: {
		ModalContractDepartament,
		ModalDepartment
	},
	data() {
		return {
			catalogue: new Catalogue(),
			load: false,
			buildings: {},
			departments: [],
			search: {
				company_id: '',
				user_id: '',
				status: '',
			}
		}
	},
	mounted() {
		this.list();
		this.setHeaderTitle('Propiedades');
		this.catalogue.get_companies_buildings();
		this.catalogue.get_users_profile(7);

	},
	created() {
		Event.$on("event-load-buildins", () => {
			this.list();
		});
		Event.$on("event-load-departaments", () => {
			this.list();
		});
	},
	methods: {
		downloadDepartments() {
            window.open(this.globalUrlFiles + '/buildings/departments');
        },
		editDepartment(department) {
			console.log(department);
			Event.$emit('event-show-addDepartament', department);
		},        
		showContracts(department) {
			console.log(department);
			Event.$emit('event-show-ContractsDepartamentAll', department);
		},
		downloadExcel() {
			let data = new URLSearchParams(this.search).toString();
			let url = this.globalUrlFiles + '/buildings/export?number=' + this.authUser.id + '&' + data;
			window.open(url, '_blank');
		},
		changeStatus: function (building) {
			let tit = 'Archivar propiedad';
			let txt = 'Seguro que quiere archivar la propiedad';
			if (building.deactivate_at) {
				tit = 'Sacar del archivo';
				txt = 'Seguro que quiere sacar del archivo la propiedad';
			}

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callChangeStatus(building.id);
				}
			});
		},
		async callChangeStatus(id) {
			let _endpoint = '/buildings/buildings_status/' + id;
			try {
				let response = await request.post(_endpoint).then(function (response) { return response; });
				this.list();
				this.successNotification(response.data.message);
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		deleted: function (id) {
			let tit = 'Eliminar propiedad';
			let txt = 'Seguro que quiere eliminar la propiedad';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callDeleted(id);
				}
			});
		},
		async callDeleted(id) {
			let _endpoint = '/buildings/' + id;
			try {
				let response = await request.destroy(_endpoint).then(function (response) { return response; });
				this.list();
				this.successNotification(response.data.message);
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		showImport() {
			this.$modal.show('example');
		},
		async list() {
			this.departments = [];
			this.load = true;
			let service = request;
			let search = this.search;
			let _endpoint = '/buildings/departments_used';
			try {
				let { data } = await service.post(_endpoint, search)
				if (data) {
					this.load = false;
					console.log(data);
					this.departments = data;
				}
			} catch (error) {
				this.load = false;
				console.log(error);
			}

		},
		...mapActions({
			resetPassword: 'auth/resetPass',
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		}),
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		}),
	},
	computed: {
		...mapGetters({
			'authUser': 'auth/user'
		})
	},
	watch: {
		"search": {
			handler: function () {
				this.list();
			},
			deep: true
		}
	}	
}
</script>

<style scoped>
.pagination {
	margin-bottom: 0;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
	color: #ffffff;
	background-color: #214d83;
	border-color: #dee2e6 #dee2e6 #fff;
}

.tbl-pays {
	table-layout: fixed;
	min-width: 900px;
	width: 100%;
}
</style>
