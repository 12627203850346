<script>
import { mapActions, mapGetters } from 'vuex';
import request from '@/services/request'
import Catalogue from '@/services/catalogue';

export default {
    props: {
        prop_edit: {
            default: true,
        }
    },
    data() {
        return {
            catalogue: new Catalogue(),
            errors: {},
            form: {
                id: '',
                building_id: '',
                number: '',
                square_meter: '',
                type_department_id: '',
                level: '',
                file: '',
                description: '',
                files: [],
                _method: 'POST',
            },
            files: [],
            file: '',
            fileName: ''
        }
    },
    methods: {
        uploadFiles(event) {
            console.log(event.target.files[0]);
            this.form.files.push(event.target.files[0]);
        },
        createURL(file) {
            let link = URL.createObjectURL(file);
            return link;
        },
        delImg(pos) {
            this.$swal({
                title: 'Eliminar archivo',
                text: 'Seguro que quiere eliminar el archivo',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.form.files.splice(pos, 1);
                    this.successNotification('El archivo se elimino correctamente.');
                }
            });
        },
        async delImgUpload(pos) {
            this.$swal({
                title: 'Eliminar archivo',
                text: 'Seguro que quiere eliminar el archivo',
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancelar'
            }).then(async (result) => {
                if (result.isConfirmed) {
                    let img = this.files[pos];
                    let id = img.id;
                    let _endpoint = '/departments/deleted-documents';
                    let form = {
                        id: id
                    };
                    let { data } = await request.postMedia(_endpoint, form)
                    if (data) {
                        this.successNotification(data.message);
                        this.files.splice(pos, 1);
                    }
                }
            });

        },
        closedModal() {
            this.form.id = '';
            this.form.number = '';
            this.form.level = '';
            this.form.file = '';
            this.form.square_meter = '';
            this.form.description = '';
            this.form.files = [];
            this.file = '';
            this.form.type_department_id = '';
            this.fileName = '';
            this.files = [];
            this.$modal.hide('addDepartament');
        },
        catch_file(event) {
            const file = event.target.files[0];

            if (!file) return; // Se nenhum arquivo for selecionado, sair da função

            const allowedTypes = ['application/pdf', 'image/jpeg', 'image/png', 'image/gif'];

            if (!allowedTypes.includes(file.type)) {

                this.$swal({
                    icon: 'error',
                    title: 'No puede subir archivo',
                    text: 'Solo se permiten archivos PDF o imágenes (JPG, PNG, GIF).',
                });
                event.target.value = ''; // Resetar input
                return;
            }

            this.form.file = file;
            this.fileName = file.name;
            // this.form.file = event.target.files[0];
            // this.fileName = event.target.files[0].name;
        },
        async saveBuildings() {
            try {
                let form = this.form;
                const data_form = new FormData();
                data_form.append('id', this.form.id);
                data_form.append('building_id', this.form.building_id);
                data_form.append('number', this.form.number);
                data_form.append('level', this.form.level);
                data_form.append('file', this.form.file);
                data_form.append('square_meter', this.form.square_meter);
                data_form.append('description', this.form.description);
                data_form.append('type_department_id', this.form.type_department_id);

                this.form.files.forEach(function (valor, index) {
                    data_form.append('files[' + index + ']', valor);
                });


                let _endpoint = '/departments/store';
                if (form.id != "") {
                    _endpoint = `/departments/store/${form.id}`;
                }

                let { data } = await request.postMedia(_endpoint, data_form)
                if (data) {
                    this.successNotification(data.message);
                    this.closedModal();
                    Event.$emit('event-load-departaments');
                }
            } catch (error) {
                this.errors = {};
                if (error.response) {
                    this.errors = await this.errorNotification(error);
                }
            }
        },
        ...mapActions({
            resetPassword: 'auth/resetPass',
            errorNotification: 'alert/error',
            successNotification: 'alert/success',
        }),
    },
    mounted() {
        let id = this.$route.params.id;
        if (id) {
            this.form.building_id = id;
        }
        this.catalogue.get_users_profile(9);
        this.catalogue.get_type_departments();
    },
    created() {
        Event.$on("event-show-addDepartament", (info) => {
            if (info) {
                this.form.id = info.id;
                this.form.building_id = info.building_id;
                this.form.number = info.number;
                this.form.level = info.level;
                this.form.description = info.description;
                this.form.square_meter = info.square_meter;
                this.form.type_department_id = info.type_department_id;

                if (info.file) {
                    this.file = info.file;
                }

                if (info.files) {
                    this.files = info.files;
                }

            }
            this.$modal.show('addDepartament');
        });
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    }
}
</script>
<template>
    <modal v-bind:click-to-close="false" name="addDepartament" :width="'80%'" :scrollable="true" :maxHeight="300"
        :height="'auto'">
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <form @submit.prevent="saveBuildings" method="post" enctype="multipart/form-data">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">Información Pago</h5>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-6 mt-3">
                        <label>Número</label><br>
                        <input class="form-control" v-model="form.number">
                        <div v-if="errors && errors.number" class="text-danger">{{ errors.number[0] }}</div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <label>Piso</label>
                        <input class="form-control" v-model="form.level">
                        <div v-if="errors && errors.level" class="text-danger">{{ errors.level[0] }}</div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <label>Metros cuadrados</label>
                        <input class="form-control" v-model="form.square_meter" type="number" step="any">
                        <div v-if="errors && errors.square_meter" class="text-danger">{{ errors.square_meter[0] }}</div>
                    </div>
                    <div class="col-md-6 mt-3">
                        <label>Tipo</label>
                        <select v-model="form.type_department_id" class="form-control">
                            <option v-for="(type, index) in catalogue.type_departments" :value="type.id" :key="index">
                                {{ type.name }}</option>
                        </select>
                        <div v-if="errors && errors.type_department_id" class="text-danger">{{
                            errors.type_department_id[0] }}</div>
                    </div>

                    <div class="col-md-12 mt-3">
                        <label>Descripción</label>
                        <textarea type="text" :disabled="!prop_edit" class="form-control"
                            v-model="form.description"></textarea>
                        <div v-if="errors && errors.description" class="text-danger">{{ errors.description[0] }}</div>
                    </div>
                    <div class="col-md-12 mt-3">
                        <label>Planos</label><br>
                        <div class="custom-file" :style="file != '' ? 'width: 87%' : 'width: 100%'">
                            <input type="file" class="custom-file-input" :class="file != '' ? 'pr-5' : ''"
                                id="customFile" ref="file" @change="catch_file">
                            <label class="custom-file-label text-left" for="customFile">{{ fileName }}</label>
                        </div>
                        <a :href="`${globalUrlFiles}${file}`" v-if="file != ''" target="_blank"
                            style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-info"> <i
                                class="fa-regular fa-file-pdf"></i>
                        </a>
                        <br>
                        <div v-if="errors && errors.file" class="text-danger">{{ errors.file[0] }}</div>

                    </div>

                    <div class="col-12 mt-3">
                        <hr>
                        <div class="row">
                            <div class="col-12 text-center">

                                <h3>Archivo adjuntos.</h3>
                                <p>Máximo 5 archivos.</p>


                                <div class="row mt-5 mb-5">
                                    <div class="col-12" v-if="(form.files.length + files.length) == 0">
                                        <i class="fa-solid fa-file fa-4x"></i>
                                        <p>Sin archivos adjuntos</p>
                                    </div>
                                    <div class="col-md-4 col-12 p-2" v-for="(fAdd, index_fAdd) in files"
                                        :key="index_fAdd" v-show="files.length > 0">
                                        <div class="card shadow">
                                            <button class="btn btn-sm btn-danger del" type="button"
                                                @click="delImgUpload(index_fAdd)">
                                                <i class="fa fa-trash"></i></button>
                                            <div class="card-body text-center">
                                                <a :href="`${globalUrlFiles}${fAdd.file}`" target="_blank">
                                                    <i class="fa fa-file fa-4x"></i>
                                                    <p>{{ fAdd.name }}</p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-12 p-2" v-for="(img, index_img) in form.files"
                                        :key="index_img" v-show="form.files.length > 0">
                                        <div class="card shadow">
                                            <button class="btn btn-sm btn-danger del" type="button"
                                                @click="delImg(index_img)"><i class="fa fa-trash"></i></button>
                                            <div class="card-body text-center">
                                                <a :href="createURL(img)" target="_blank">
                                                    <i class="fa fa-file fa-4x"></i>
                                                    <p>{{ img.name }}</p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="image-upload btn btn-outline-secondary btn-sm"
                                    v-if="(form.files.length + files.length) <= 4">
                                    <label for="file-input" class="m-0">
                                        <h4 class="m-0"> <i class="fa-solid fa-file fa-1x mr-2"></i>
                                            Subir
                                            Archivo</h4>
                                    </label>
                                    <input id="file-input" type="file" @change="uploadFiles" />
                                </div>
                            </div>

                        </div>
                    </div>


                    <div class="col-md-12">
                        <hr>
                        <button v-if="prop_edit" type="submit" class="btn float-right btn-outline-success">
                            <i class="fas fa-save"></i> Guardar
                        </button>
                        <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                            <i class="fas fa-undo-alt"></i>
                            Regresar
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </modal>
</template>