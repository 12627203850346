<template>
	<div class="col-12 ">
		<div class="card card-outline card-navy">
			<div class="card-body">
				<form @submit.prevent="triggerSave">
					<div class="row">
						<div class="col-md-6">
							<div class="form-group">
								<label> Nombre </label>
								<input type="text" v-model="form.name" class="form-control">
								<div v-if="errors && errors.name" class="text-danger">{{ errors.name[0] }}</div>
							</div>
						</div>
						<div class="col-md-6">
							<div class="form-group">
								<label> R.F.C </label>
								<input type="text" v-model="form.rfc" class="form-control">
								<div v-if="errors && errors.rfc" class="text-danger">{{ errors.rfc[0] }}
								</div>
							</div>
						</div>
						<div class="col-md-12">
							<div class="form-group">
								<label> Régimen Fiscal </label>
								<input type="text" v-model="form.tax_regime" class="form-control">
								<div v-if="errors && errors.tax_regime" class="text-danger">{{ errors.tax_regime[0] }}
								</div>
							</div>
						</div>
						<div class="col-md-12">
							<div class="form-group">
								<label> Dirección </label>
								<input type="text" v-model="form.address" class="form-control">
								<div v-if="errors && errors.address" class="text-danger">{{ errors.address[0] }}
								</div>
							</div>
						</div>
						<div class="col-md-10">
							<div class="form-group">
								<label>Representante legal</label>
								<input type="text" v-model="form.representative" class="form-control">
								<div v-if="errors && errors.representative" class="text-danger">{{ errors.representative[0] }}
								</div>
							</div>
						</div>

						<div class="col-md-2">
							<div class="form-group">
								<label>Pago de nomina</label>
								<input type="checkbox" v-model="form.pays_payroll" class="form-control"> 
								<div v-if="errors && errors.pays_payroll" class="text-danger">{{ errors.pays_payroll[0] }}
								</div>
							</div>
						</div>
						<div class="col-md-12">
							<div class="form-group">
								<label> Cedula fiscal </label>
								<br>
								<!-- <input type="file" v-model="form.cedula" class="form-control"> -->
								<div class="custom-file" :style="file_cedula != '' ? 'width: 87%' : 'width: 100%'">
									<input type="file" class="custom-file-input" :class="file_cedula != '' ? 'pr-5' : ''"
										id="customFile" ref="file" @change="cedula_file">
									<label class="custom-file-label text-left" for="customFile">{{ cedularName }}</label>
								</div>
								<a :href="file_cedula" v-if="file_cedula != ''" target="_blank"
									style="float: right; border-radius: 5px 0px 5px 0px;" class="btn btn-info"> <i
										class="fa-regular fa-file-pdf"></i></a>

								<div v-if="errors && errors.cedula" class="text-danger">{{ errors.cedula[0] }}</div>
							</div>
						</div>
						<div class="col-12 mb-2 text-right">
							<hr>
							<button type="button" @click="newDepartment()" v-can="'add_bank_accounts'"
								class="btn btn-sm btn-outline-info">
								<i class="fa fa-plus"></i> Cuenta
							</button>
						</div>
						<div class="col-md-12">
							<div class="table-responsive">
								<table class="table table-bordered text-center text-sm">
									<thead>
										<tr>
											<th>ID</th>
											<th>Nombre</th>
											<th>Número</th>
											<th>Tipo</th>
											<th>Banco</th>
											<th>Seleccionar</th>
										</tr>
									</thead>
									<tbody v-if="bank_accounts">
										<tr v-for="(account, index) in bank_accounts" :key="index">
											<td>{{ account.id }}</td>
											<td>{{ account.name }}</td>
											<td>{{ account.number }}</td>
											<td>
												<span v-if="account.type_account_bank">{{ account.type_account_bank.name
												}}</span>
												<span v-else>Sin tipo</span>
											</td>
											<td>
												<span v-if="account.bank">{{ account.bank.name }}</span>
												<span v-else>Sin tipo</span>
											</td>
											<td>
												<input v-model="form.bank_accounts" :value="account.id" type="checkbox"
													class="form-control form-control-sm">
											</td>
										</tr>
									</tbody>
									<tbody v-else>
										<tr>
											<td align="center" colspan="5">Sin resultados.</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
						<div class="col-12">
							<hr>
						</div>
						<div class="col-md-12">
							<button type="submit" class="btn float-right btn-outline-success">
								<i class="fas fa-save"></i> Guardar
							</button>
							<router-link to="/users" class="btn float-left btn-outline-warning">
								<i class="fas fa-undo-alt"></i>
								Regresar
							</router-link>
						</div>
					</div>
				</form>

			</div>
		</div>
		<ModalFormComponent />
	</div>
</template>

<script>
import request from '@/services/request'
import { mapActions } from 'vuex';
import ModalFormComponent from '@/components/bank_accounts/ModalFormComponent.vue';

export default {
	components: {
		ModalFormComponent
	},
	name: "users-table",
	data() {
		return {
			form: {
				id: '',
				name: '',
				tax_regime: '',
				address: '',
				rfc: '',
				representative: '',
				pays_payroll: false,
				cedula:'',
				bank_accounts: []
			},
			bank_accounts: [],
			errors: {},
			// 
			file_cedula: '',
			cedularName:''
		}
	},
	mounted() {
		this.accountBanks();
		let id = this.$route.params.id;
		if (id) {
			this.get_id(id);
		}
	},
	methods: {
		cedula_file(event) {
			console.log(event.target.files[0].type);
			console.log(event.target.files[0].name);
			if (event.target.files[0].type == "application/pdf") {
				this.form.cedula = event.target.files[0];
				this.cedularName = event.target.files[0].name;
			} else {
				this.$swal({
					icon: 'error',
					title: 'Error tipo de archivo',
					text: 'Solo se puede cargar archivos PDF',
				});
			}
		},
		newDepartment(account = {}) {
			Event.$emit('event-show-addContBanck', account );
		},
		async triggerSave() {
			const data_form = new FormData();
			data_form.append('id', this.form.id);
			data_form.append('name', this.form.name);
			data_form.append('rfc', this.form.rfc);
			data_form.append('cedula', this.form.cedula);
			data_form.append('representative', this.form.representative);
			data_form.append('pays_payroll', this.form.pays_payroll);
			data_form.append('bank_accounts', this.form.bank_accounts);
			data_form.append('tax_regime', this.form.tax_regime);
			data_form.append('address', this.form.address);
			let _endpoint = '/companies';
			if (this.form.id != "") {
				data_form.append('_method', 'PUT');
				_endpoint = `/companies/${this.form.id}`;
			}

			try {
				let { data } = await request.postMedia(_endpoint, data_form)
				if (data) {
					console.log(data);
					// this.users = data.data;
					this.successNotification(data.message);
					this.$router.push('/companies');
				}
			} catch (error) {
				this.errors = await this.errorNotification(error);
			}
		},
		async get_id(id) {
			let _endpoint = `/companies/${id}`;
			try {
				let { data } = await request.get(_endpoint)
				if (data) {
					this.form.id = id;
					this.form.name = data.name;
					this.form.rfc = data.rfc;
					this.form.representative = data.representative;
					this.form.pays_payroll = data.pays_payroll == 1 ? true : false;
					this.form.tax_regime = data.tax_regime;
					this.form.address = data.address;

					if (data.cedula) {
						this.file_cedula = this.globalUrlFiles + data.cedula;
					}
					
					let accounts = [];
					data.bank_accounts.forEach(function (valor) {
						accounts.push(valor.id);
					});
					this.form.bank_accounts = accounts;
				}
			} catch (error) {
				this.errors = {};
				if (error.response) {
					await this.errorNotification(error)
				}
			}
		},
		async accountBanks() {
			let _endpoint = '/bank_accounts';
			try {
				let { data } = await request.get(_endpoint)
				if (data) {
					console.log(data);
					this.bank_accounts = data;
				}
			} catch (error) {
				console.log(error);
			}
		},
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		})
	},
	created() {
			Event.$on("event-load-bank_accounts", () => {
				this.accountBanks();
			});
	},
}
</script>

<style scoped>
.pagination {
	margin-bottom: 0;
}

.mx-datepicker {
	width: 100%;
}

.fs-6 {
	font-size: 12px;
}

.custom-file-label::after {
	content: "Archivo";
}
</style>
