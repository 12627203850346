<script>
import { mapGetters } from 'vuex';

import ModalFormComponent from '@/components/departments/ModalFormComponent.vue';
import ModalUserDepartament from '@/components/departments/ModalUserDepartamentComponent.vue';
import ModalListPaysServices from '@/components/departments/ModalListPaysServicesComponent.vue';
import ModalContractDepartament from '@/components/departments/ModalContractDepartamentComponent.vue';
import ModalContract from '@/components/contracts/ModalTableContractsComponent.vue';
import Catalogue from '@/services/catalogue';
import request from '@/services/request';

export default {
    name: "departaments-table",
    components: {
        ModalFormComponent,
        ModalUserDepartament,
        ModalListPaysServices,
        ModalContractDepartament,
        ModalContract
    },
    data() {
        return {
            departments: {},
            search: {
                search: '',
                building_id: '',
                department_id: '',
            },
            increases: [],
            searchAll: '',
            page: 1,
            catalogue: new Catalogue(),
        }
    },
    methods: {
        getIncrease(departament) {
            let depo = departament.current_tenant.deposit;
            let increase = departament.current_tenant.rent_increase;
            let sumas = 0;
            increase.forEach(element => {
                if (element.check != true) {
                    if (element.type == 1) {
                        let dd = element.amount * 1;
                        sumas += dd;
                    }

                    if (element.type == 2 || element.type == 3) {
                        let dd = (depo * 1) * element.value / 100;
                        sumas += dd;
                    }
                }
            });
            sumas += depo;
            return sumas;
        },
        closedModal() {
            this.$modal.hide('ListAuments');
        },
        getIncreaseList(departament) {
            this.$modal.show('ListAuments');

            this.increases = departament.current_tenant.rent_increase;
        },
        async list(page = this.page) {
            this.load = true;
            let service = request;
            let search = {};
            search.search = this.searchAll;
            search.building_id = this.search.building_id;
            search.department_id = this.search.department_id;

            let _endpoint = '/departments/list-used';
            try {
                let { data } = await service.list(_endpoint, page, search)
                if (data) {
                    console.log(data);
                    this.departments = data;
                    this.load = false;
                    this.selected = '';
                }
            } catch (error) {
                console.log(error);
                this.load = false;
            }
        },
        formaContract(info) {
            Event.$emit('event-show-addContract', info);
        },
        formDepartament(info) {
            Event.$emit('event-show-addDepartament', info);
        },
        formPayServicesDepartament(department) {
            Event.$emit('event-load-pays-services', department);
        },
        formDepartamentUser(departament, tenant) {
            Event.$emit('event-show-addDepartamentUser', departament, tenant);
        },
        showContract(departament) {
            Event.$emit('event-show-ContractsDepartament', departament);
        },
        editTenant(buy = {}, edit = false) {
            this.editForm = edit;
            Event.$emit('event-show-addDepartamentUser', buy);
        },
    },
    mounted() {
        this.list();
        this.catalogue.get_buildings();
    },
    created() {
        Event.$on("event-load-departaments", () => {
            this.catalogue.get_departamens_buildings(this.building_id);
        });
    },
    computed: {
        ...mapGetters({
            'authUser': 'auth/user'
        })
    },
    watch: {
        'search.building_id': {
            handler: function (newValue) {
                this.catalogue.get_departamens_buildings(newValue);
            },
            deep: true
        },
        'search': {
            handler: function () {
                this.list();
            },
            deep: true
        },
    }
}
</script>
<template>
    <div class="col-12">
        <div class="card card-outline">
            <div class="card-header">
                <h3 class="card-title">Lista de Depósitos</h3>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12">
                        <label>Buscar :</label>
                        <input type="text" class="form-control" v-model="searchAll" @keyup="list()" />
                    </div>
                    <div class="col-md-6">
                        <label>Propiedad :</label>
                        <select class="form-control" v-model="search.building_id" @change="list()">
                            <option value="">Todas</option>
                            <option :value="building.id" v-for="(building, index) in catalogue.buildings" :key="index">
                                {{ building.name }}</option>
                        </select>
                    </div>
                    <div class="col-md-6">
                        <label> Indiviso :</label>
                        <select class="form-control" v-model="search.department_id" @change="list()">
                            <option value="">Todas</option>
                            <option :value="company.id" v-for="(company, index) in catalogue.departamens_buildings"
                                :key="index">
                                {{ company.level }} /
                                {{ company.number }}
                            </option>
                        </select>
                    </div>
                </div>

                <br>
                <div class="table-responsive">
                    <table class="table table-bordered text-center text-sm">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Propiedad</th>
                                <th>Indiviso</th>
                                <th>Depósito</th>
                                <th>Arrendatario</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody v-if="departments">
                            <tr v-for="(departament, index) in departments" :key="index">
                                <td>{{ departament.id }}</td>
                                <td>
                                    <span>{{ departament.building.name }}</span>
                                </td>

                                <td>
                                    <span>
                                        {{ departament.number }}
                                    </span>
                                    /
                                    <span>
                                        {{ departament.level }}
                                    </span>
                                    <br>
                                    <hr>
                                    <span v-if="departament.current_tenant">
                                        <span v-if="departament.current_tenant.star_date"> {{ departament.current_tenant.star_date | toDateTime }} </span>
                                        <span v-if="departament.current_tenant.end_date"> - {{ departament.current_tenant.end_date | toDateTime }} </span>
                                    </span>

                                    <span v-if="departament.tenants_concurrent.length > 0">
                                        <span v-if="departament.tenants_concurrent[0].star_date"> {{ departament.tenants_concurrent[0].star_date | toDateTime }} </span>
                                        <span v-if="departament.tenants_concurrent[0].end_date"> - {{ departament.tenants_concurrent[0].end_date | toDateTime }} </span>
                                    </span>
                                </td>
                                <td>
                                    <span v-if="departament.current_tenant">
                                        {{ getIncrease(departament) | toCurrency }}
                                    </span>
                                    <span v-if="departament.tenants_concurrent.length > 0">
                                        {{ (departament.tenants_concurrent[0].deposit +
                                            getIncrease(departament.tenants_concurrent[0])) | toCurrency }}
                                    </span>
                                </td>
                                <td>
                                    <span v-if="departament.current_tenant">
                                        <span v-if="departament.current_tenant.user">
                                            {{ departament.current_tenant.user.name }}
                                            {{ departament.current_tenant.user.last_name }}
                                        </span>
                                    </span>
                                </td>
                                <td>
                                    <button v-if="departament.current_tenant" @click="getIncreaseList(departament)"
                                        v-tooltip.bottom-start="'Tooltip on bottom'"
                                        class="btn m-1 btn-outline-success btn-sm">
                                        <i class="fa-solid fa-money-bill-trend-up"></i> Aumentos
                                    </button>

                                    <button v-if="departament.tenants_concurrent.length > 0"
                                        @click="getIncreaseList(departament.tenants_concurrent[0])"
                                        v-tooltip.bottom-start="'Tooltip on bottom'"
                                        class="btn m-1 btn-outline-success btn-sm">
                                        <i class="fa-solid fa-money-bill-trend-up"></i> Aumentos
                                    </button>

                                    <button @click="showContract(departament)"
                                        v-tooltip.bottom-start="'Tooltip on bottom'"
                                        class="btn m-1 btn-outline-secondary btn-sm">
                                        <i class="fas fa-history "> </i> Historial de renta
                                    </button>

                                    <button v-if="departament.current_tenant"
                                        @click="editTenant(departament.current_tenant, false)"
                                        class="btn btn-sm btn-outline-success mr-2">
                                        <i class="fa-solid fa-file"></i> Contrato
                                    </button>

                                    <button v-if="departament.tenants_concurrent.length > 0"
                                        @click="editTenant(departament.tenants_concurrent[0], false)"
                                        class="btn btn-sm btn-outline-success mr-2">
                                        <i class="fa-solid fa-file"></i>
                                    </button>
                                </td>
                            </tr>
                        </tbody>
                        <tbody v-else>
                            <tr>
                                <td align="center" colspan="5">Sin resultados.</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <ModalFormComponent />
            <ModalUserDepartament :prop_edit="false" />
            <ModalListPaysServices />
            <ModalContractDepartament />
            <ModalContract />
            <!-- Modal Contract-->

            <modal name="ListAuments" :width="'80%'" :scrollable="true" :maxHeight="300" :height="'auto'">
                <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                        class="fa-solid fa-xmark"></i> </button>
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">Historial de contratos </h5>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <div class="table-responsive">
                                <table class="table table-bordered text-center text-sm">
                                    <thead>
                                        <tr>
                                            <th>Fechas</th>
                                            <th>Tipo</th>
                                            <th>Monto</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="increases.length > 0">
                                        <tr v-for="(increase, index) in increases" :key="index">
                                            <td>
                                                {{ increase.date | toDateTime }}
                                            </td>
                                            <td>
                                                <span v-if="increase.type == 1">
                                                    Monto
                                                </span>
                                                <span v-if="increase.type == 2">
                                                    INPC mes vencido 
                                                </span>
                                                <span v-if="increase.type == 3">
                                                    INPC mes anterior 
                                                </span>
                                            </td>
                                            <td>
                                                <span v-if="increase.type == 1 && increase.value">                                                    
                                                    {{ increase.value | toCurrency }}
                                                </span>
                                                <span v-if="(increase.type == 2 && increase.value) || (increase.type == 3 && increase.value) ">
                                                    {{ increase.value }} %
                                                </span>
                                            </td>
                                            <td>
                                                <span v-if="!increase.check">
                                                    Aplicado
                                                </span>
                                                <span v-if="increase.check">
                                                    Pendiente
                                                </span>
                                            </td>


                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td align="center" colspan="4">Sin aumentos.</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </modal>
        </div>
    </div>
</template>