<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';
import request from '@/services/request';

import ModalFormComponent from '@/components/payroll/ModalFormComponent.vue';
import Catalogue from '@/services/catalogue';

import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
	name: "payroll-table",
	components: {
		ModalFormComponent,
		DatePicker
	},
	data() {
		return {
			loadAll: false,
			payroll: {},
			name: '',
			editBill: true,
			search: {
				billing_data_id: '',
				search: '',
				date: '',
				company_id: '',
				user_id: '',
				biill_type_id: '',
				pay_order: '',
				authorized: 1,
			},
			idConvert: '',
			idUser: '',
			page: 1,
			send: false,
			catalogue: new Catalogue(),
			load: false,
			isExpanded: []
		}
	},
	async mounted() {
		this.loadAll = true;

		this.list();
		this.catalogue.get_users_profile(2);
		if(this.authUser.organization_charts_id == 10 || this.authUser.organization_charts_id == 15){ 
            this.catalogue.get_companies_users(this.authUser.id);
        }else{
            this.catalogue.get_company_pays();
        }

		this.setHeaderTitle('Nominas');
		this.catalogue.get_all_billing_data();
		if (this.authUser.organization_charts_id == 2) {
			await this.catalogue.get_validate_payment_plugin();
		}
		this.loadAll = false;

	},
	created() {
		Event.$on("event-load-payroll", () => {
			this.list();
		});
	},
	methods: {
		showPettyCash(petty = false) {
			Event.$emit('event-show-addPettyCash', petty);
		},
		showData(id) {
			let vm = this;
			console.log(id);
			let ids = this.isExpanded;
			let position = ids.indexOf(id);
			console.log(position);
			console.log(position != -1);
			console.log(position != '-1');
			if (position == -1) {
				vm.isExpanded.push(id);
			} else {
				vm.isExpanded.splice(position, 1);
			}

		},
		isVisible(id) {
			let ids = this.isExpanded;
			return ids.includes(id);
		},

		downloadFiles() {
			let filter = this.search;
			filter.type = 'bill';
			filter.user_logged = this.authUser.id;

			let data = new URLSearchParams(filter).toString();

			let _url = this.globalUrlFiles + '/payroll/download_files?' + data;
			window.open(_url, '_blank');

			// Event.$emit('event-show-downloadFiles', filter);
		},
		downloadFile(type, id) {
			let url = this.globalUrlFiles + '/payroll/download_file?id=' + id + '&type=' + type;
			window.open(url, '_blank');
		},
		AddCheck(bill = {}) {

			Event.$emit('event-show-addChecksBill', bill);
		},
		downloadExcek() {
			let data = new URLSearchParams(this.search).toString();
			let url = this.globalUrlFiles + '/payroll/export?number=' + this.authUser.id + '&' + data;
			window.open(url, '_blank');
		},
		showCheck(edit = {}) {
			Event.$emit('event-show-addChecks', edit);
		},
		showChecks(bill = {}) {
			Event.$emit('event-show-listCheckspayroll', bill);
		},
		newCredit(bill, credit) {
			Event.$emit('event-show-addPayrollCredit', bill, credit);
		},
		getIfAddChecks(bill) {
			let sum = bill.amount;
			if (bill.credit) {
				sum -= (bill.credit.amount * 1);
			}

			if (bill.checks.length > 0) {
				bill.checks.forEach(function (check) {
					sum -= (check.amount_payable * 1);
				});
			}
			return sum;
		},
		getPays(check) {
			let sum = 0;
			if (check) {
				if (check.pay) {
					check.pay.forEach(function (valor) {
						sum += parseFloat(valor.amount);
					});
				}
			}
			return sum;
		},
		redireCreate(type) {
			let url = 'add_buy';
			if (type == 1) {
				url = 'add_budgets';
			}
			let parms = { bill: this.idConvert, supplier: this.idUser };
			console.log(parms);
			this.$router.push({ name: url, query: parms });
		},
		approve(bill, edit = false) {
			console.log('bill');
			console.log(bill);
			console.log(edit);


			let tit = 'Aprobar Nomina';
			let txt = 'Seguro que quiere aprobar la Nomina';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then(async (result) => {
				if (result.isConfirmed) {
					let _endpoint = '/payroll_approve';
					let form = {
						id: bill.id
					};
					try {
						let response = await request.post(_endpoint, form).then(function (response) { return response; });
						this.list();
						this.successNotification(response.data.message);
					} catch (error) {
						this.errors = await this.errorNotification(error)
					}
				}
			});

		},
		cancelBill(id) {
			let vm = this;
			console.log(id);
			this.$swal({
				title: 'Motivos de Cancelación',
				input: 'text',
				icon: 'warning',
				inputAttributes: {
					autocapitalize: 'off'
				},
				showCancelButton: true,
				confirmButtonText: 'Guardar',
				showLoaderOnConfirm: true,
				preConfirm: async (message) => {
					console.log(message);
					let form = {
						id: id,
						message: message,
					};
					let _endpoint = '/cancel_payroll';
					try {
						let { data } = await request.postMedia(_endpoint, form)
						if (data) {
							console.log(data);
							await vm.successNotification(data.message);
							await vm.list();
						}
					} catch (error) {
						vm.errors = {};
						if (error.response) {
							vm.errors = vm.errorNotification(error);
						}
					}
				},
				allowOutsideClick: () => !this.$swal.isLoading()
			}).then((result) => {
				if (result.isConfirmed) {
					console.log(result);
				}
			})

		},
		convertBill(bill) {
			console.log('bill');
			console.log(bill);
			// let date = this.moment(bill.created_at).format("YYYY-MM-DD");
			// let amount = 0;
			// if (bill.credit) {
			// 	amount = bill.credit.amount;
			// }

			Event.$emit('event-add-addChecksBill', bill);

			// this.AddCheck(bill);
			// this.$router.push('/checks');
		},
		async newDepartment(account = {}, edit = true) {
			this.editBill = edit;
			Event.$emit('event-show-addPayroll', account);
		},
		newPay(account = {}) {
			console.log(account);
			Event.$emit('event-show-addPays', account);
		},
		deleted: function (id) {
			let tit = 'Eliminar Nomina';
			let txt = 'Seguro que quiere eliminar la Nomina';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callDeleted(id);
				}
			});
		},
		async callDeleted(id) {
			let _endpoint = '/payroll/' + id;
			try {
				let response = await request.destroy(_endpoint).then(function (response) { return response; });
				this.list();
				this.successNotification(response.data.message);
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		showImport() {
			this.$modal.show('example');
		},
		downloadRecibe(id) {
			window.open(this.globalUrlFiles + '/payroll/pdf/' + id, '_blank');
		},
		async list(page = 1) {
			this.load = true;
			let service = request;
			let search = this.search;
			let _endpoint = '/payroll';
			search.search = this.name;
			try {
				let { data } = await service.list(_endpoint, page, search)
				if (data) {
					console.log(data);
					this.payroll = data;
					this.load = false;

				}
			} catch (error) {
				console.log(error);
				this.load = false;

			}
		},
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		}),
		...mapActions({
			resetPassword: 'auth/resetPass',
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		}),
	},
	watch: {
		'name': {
			handler: function (val) {
				if (val == '') {
					this.list();
				}
			},
			deep: true
		},
		'search': {
			handler: function () {
				this.list();
			},
			deep: true
		}
	},
	computed: {
		...mapGetters({
			'authUser': 'auth/user'
		})
	},
}
</script>

<template>
	<div class="col-12">
		<div class="card card-outline card-navy">
			<div class="card-header">
				<h3 class="card-title">Lista de Nominas</h3>
				<div class="card-tools">
					<button @click="newDepartment()" v-can="'add_payroll'" class="btn btn-sm btn-outline-info">
						<i class="fa fa-plus"></i> Nomina
					</button>

				</div>
			</div>
			<div class="card-body">
				<div class="row">

					<div class="col-md-6">
						<label>Rango de fechas :</label>
						<date-picker format="YYYY-MM-DD" valueType="format" :range="true"
							v-model="search.date"></date-picker>
					</div>
					<div class="col-md-6">
						<label>Empresa :</label>

						<select v-model="search.company_id" class="form-control" v-if="authUser.organization_charts_id != 10 && authUser.organization_charts_id != 15">
							<option value=""> Sin empresa </option>
							<option v-for="(company, index) in catalogue.company_pays" :value="company.id" :key="index">
								{{ company.name }}</option>
						</select>

						<select v-model="search.company_id" class="form-control" v-else>
                            <option value=""> Sin empresa </option>
                            <option v-for="(company, index) in catalogue.companies_users" :value="company.id" :key="index">
                                {{ company.name }}
							</option>
                        </select>
					</div>

				</div>
				<br>
				<div class="row">
					<div class="col-md-12">
						<ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
							<li class="nav-item">
								<a class="nav-link active" @click="search.authorized = 1" data-toggle="pill" role="tab"
									aria-selected="true">Por pagar</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" @click="search.authorized = 2" data-toggle="pill" role="tab"
									aria-selected="false">Pagados</a>
							</li>
						</ul>
					</div>
				</div>
				<div class="table-responsive">
					<table class="table table-bordered text-center text-sm">
						<thead>
							<tr>
								<th>ID</th>
								<th>Fecha</th>
								<th>Compañía</th>
								<th>Fecha inicio</th>
								<th>Fecha fin</th>
								<th>Dispersión</th>
								<th>Monto</th>
								<th>Opciones</th>
							</tr>
						</thead>
						<tbody v-if="load">
							<tr>
								<td align="center" colspan="8">
									<i class="fa-solid fa-circle-notch fa-spin fa-3x mb-1"></i>
									<h5>Cargando</h5>

								</td>
							</tr>
						</tbody>
						<tbody v-else-if="payroll.total > 0">
							<tr v-for="(payroll, index) in payroll.data" :key="index">
								<td>
									{{ payroll.id }}
								</td>

								<td>{{ payroll.date | toDateTime }}</td>
								<td>
									<span v-if="payroll.company">{{ payroll.company.name }}</span>
									<span v-else> Sin empresa </span>
								</td>
								<td>{{ payroll.date_start | toDateTime }}</td>
								<td>{{ payroll.date_end | toDateTime }}</td>
								<td>
									<span v-if="payroll.dispersion">
										SI
									</span>
									<span v-else>
										NO
									</span>
								</td>

								<td class="text-right">{{ payroll.amount | toCurrency }}</td>

								<td>
									<button @click="approve(payroll)" v-can="'approve_payroll'" v-if="payroll.checks.length == 0"
										class="btn m-1 btn-outline-success btn-sm">
										<i class="fas fa-check "> </i> Aprobado
									</button>

									<button @click="newDepartment(payroll, true)" v-can="'edit_payroll'"
										v-if="payroll.checks.length == 0" class="btn m-1 btn-outline-primary btn-sm">
										<i class="fas fa-edit "> </i> Editar
									</button>
									<button @click="newDepartment(payroll, false)"
										class="btn m-1 btn-outline-secondary btn-sm">
										<i class="fas fa-eye "> </i> Ver más
									</button>

									<button type="button" @click="deleted(payroll.id)" v-can="'deleted_payroll'"
										v-if="payroll.checks.length == 0" class="btn m-1 btn-outline-danger btn btn-sm ">
										<i class="fa fa-trash"></i> Eliminar
									</button>




								</td>

							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td align="center" colspan="9">Sin resultados.</td>
							</tr>
						</tbody>
					</table>
					<pagination v-model="page" :records="parseInt(payroll.total)" :per-page="parseInt(payroll.per_page)"
						@paginate="list" />
				</div>
			</div>
		</div>
		<ModalFormComponent :prop_edit="editBill" />
	</div>
</template>

<style scoped>
.pagination {
	margin-bottom: 0;
}

.fade-enter-active,
.fade-leave-active {
	transition: opacity .5s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active below version 2.1.8 */
	{
	opacity: 0;
}
</style>
