<script>
import { mapMutations, mapActions, mapGetters } from 'vuex';
import request from '@/services/request';
import Pagination from 'vue-pagination-2';
import Catalogue from '@/services/catalogue';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import ModalFormTax from '@/components/tax/ModaFormComponent.vue'
import ModalFormVoucher from '@/components/tax/ModalFormVoucherComponent.vue'
import ModalTableComponentPays from '@/components/pays/ModalTableComponent.vue';

export default {
	name: "taxes-table",
	components: {
		Pagination,
		DatePicker,
		ModalFormTax,
		ModalFormVoucher,
		ModalTableComponentPays
	},
	data() {
		return {
			loadAll: false,
			data: {},
			name: '',
			editTax: true,
			search: {
				search: '',
				date: '',
				company_id: '',
				user_id: '',
				type_id: '',
				taxes_types_id: '',
				deactivate : 1
			},
			idConvert: '',
			idUser: '',
			page: 1,
			send: false,
			catalogue: new Catalogue(),
			load: false,
		}
	},
	async mounted() {
		this.loadAll = true;

		this.list();
		this.catalogue.get_companies_buildings();
		this.catalogue.get_taxes_types();
		this.loadAll = false;

	},
	created() {
		Event.$on("event-load-taxes", () => {
			this.list();
		});
	},
	methods: {
		uploadVoucher(check) {
			Event.$emit('event-show-addVoucher', check);
		},
		getPays(check) {
			let sum = 0;
			if (check) {
				if (check.pay.length > 0) {
					check.pay.forEach(function (valor) {
						sum += parseFloat(valor.amount);
					});
				}
			}
			return sum;
		},
		AddTaxes(tax = {}, edit = true) {
			this.editTax = edit;
			Event.$emit('event-show-addTaxes', tax);
		},
		approvedSelected(id) {
			let tit = 'Aprobar impuesto';
			let txt = 'Se aprobaran el impuesto';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Si, aprobar',
				cancelButtonText: 'Cancelar'
			}).then(async (result) => {
				if (result.isConfirmed) {
					let _endpoint = '/taxes_approve';
					let form = {
						id: id
					};
					try {
						let response = await request.post(_endpoint, form).then(function (response) { return response; });
						this.list();
						Event.$emit('event-check-cancel');
						this.successNotification(response.data.message);
					} catch (error) {
						this.errors = await this.errorNotification(error)
					}

				}
			});
		},
		async list(page = 1) {
			this.load = true;
			let service = request;
			let search = this.search;
			let _endpoint = '/taxes';
			search.search = this.name;
			try {
				let { data } = await service.list(_endpoint, page, search)
				if (data) {
					console.log(data);
					this.data = data;
					this.load = false;

				}
			} catch (error) {
				console.log(error);
				this.load = false;

			}
		},
		changeStatus: function (building) {
			let tit = 'Archivar impuesto';
			let txt = 'Seguro que quiere archivar el impuesto';
			if(building.deactivate_at){
				tit = 'Sacar del archivo';
				txt = 'Seguro que quiere sacar del archivo el impuesto';
			}
			
			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callChangeStatus(building.id);
				}
			});
		},
		async callChangeStatus(id) {
			let _endpoint = '/taxes_status/' + id;
			try {
				let response = await request.post(_endpoint).then(function (response) { return response;});
				this.list();
				this.successNotification(response.data.message);			
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		deleted: function (id) {
			let tit = 'Eliminar Impuesto';
			let txt = 'Seguro que quiere eliminar el Impuesto';

			this.$swal({
				title: tit,
				text: txt,
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Ok',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					this.callDeleted(id);
				}
			});
		},
		async callDeleted(id) {
			let _endpoint = '/taxes/' + id;
			try {
				let response = await request.destroy(_endpoint).then(function (response) { return response; });
				this.list();
				this.successNotification(response.data.message);
			} catch (error) {
				this.errors = await this.errorNotification(error)
			}
		},
		formListPays(check) {
			Event.$emit('event-show-listPays', check);
		},
		...mapMutations({
			setHeaderTitle: 'setHeaderTitle',
		}),
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		}),
	},
	watch: {
		'name': {
			handler: function (val) {
				if (val == '') {
					this.list();
				}
			},
			deep: true
		},
		'search': {
			handler: function () {
				this.list();
			},
			deep: true
		}
	},
	computed: {
		...mapGetters({
			'authUser': 'auth/user'
		})
	},
}
</script>

<template>
	<div class="col-12">
		<ModalTableComponentPays :prop_edit="true" />
		<ModalFormVoucher />
		<ModalFormTax :prop_edit="editTax" />
		<div class="card card-outline card-navy">
			<div class="card-header">
				<h3 class="card-title">Lista de Impuestos</h3>
				<div class="card-tools">
					<!-- <button @click="downloadFiles()" class="btn btn-sm btn-outline-secondary mr-1">
						<i class="fa-solid fa-box-archive"></i> Archivo de facturas
					</button>
					<button @click="downloadExcek()" v-can="'add_bill'" class="btn btn-sm btn-outline-success mr-1">
						<i class="fa-solid fa-file-excel"></i> Excel
					</button> -->
					<button v-if="!loadAll" @click="AddTaxes()" v-can="'add_tax'" class="btn btn-sm btn-outline-info">
						<i class="fa fa-plus"></i> Impuesto
					</button>

				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-md-7 col-lg-8">
						<label>Buscar :</label>
						<input class="form-control" type="search" v-debounce:1s="list" v-model="name">
					</div>
					<div class="col-md-5 col-lg-4">
						<label>Rango de fechas :</label>
						<date-picker format="YYYY-MM-DD" :range="true" v-model="search.date"></date-picker>
					</div>
					<div class="col-md-6">
						<label>Empresa :</label>
						<select v-model="search.company_id" class="form-control">
							<option value="">Todos</option>
							<option v-for="(company, index) in catalogue.companies_buildings" :key="index"
								:value="company.id"> {{ company.name }}
							</option>
						</select>
					</div>
					<div class="col-md-6">
						<label>Tipo :</label>
						<select class="form-control" v-model="search.taxes_types_id">
							<option value="">Todos</option>
							<option v-for="(type, index) in catalogue.taxes_types" :value="type.id" :key="index">{{
			type.name }}</option>
						</select>
					</div>
				</div>
				<br>
				<div class="row">
					<div class="col-md-12">
						<ul class="nav nav-tabs" id="custom-tabs-one-tab" role="tablist">
							<li class="nav-item">
								<a class="nav-link active" @click="search.deactivate = 1" data-toggle="pill" role="tab"
									aria-selected="true">Activas</a>
							</li>
							<li class="nav-item">
								<a class="nav-link" @click="search.deactivate = 2" data-toggle="pill" role="tab"
									aria-selected="false">Archivadas</a>
							</li>
						</ul>
					</div>
				</div>
				<div class="table-responsive">
					<table class="table table-bordered text-center text-sm">
						<thead>
							<tr>
								<th>ID</th>
								<th>Empresa</th>
								<th>Fecha</th>
								<th>Tipo</th>
								<th>Tipo de saldo</th>
								<th>Monto</th>
								<th>Pagado</th>
								<th>Opciones</th>
							</tr>
						</thead>
						<tbody v-if="load">
							<tr>
								<td align="center" colspan="9">
									<i class="fa-solid fa-circle-notch fa-spin fa-3x mb-1"></i>
									<h5>Cargando</h5>

								</td>
							</tr>
						</tbody>
						<tbody v-else-if="data.total > 0">
							<tr v-for="(val, index) in data.data" :key="index">
								<td>
									{{ val.id }}
									<i v-can="'receipt_tax'" v-if="getPays(val.check) == val.amount && (val.voucher == '' || val.voucher == null )" class="fa-solid fa-receipt text-warning fa-xl fa-fade"></i>									
								</td>
								<td>
									<div v-if="val.company">
										{{ val.company.name }}
									</div>
									<div v-else>
										Sin empresa
									</div>
								</td>
								<td>
									{{ val.date | toDateTime }}
								</td>
								<td>
									<div v-if="val.type">
										{{ val.type.name }}
									</div>
									<div v-else>
										Sin tipo
									</div>
								</td>
								<td>
									<div v-if="val.type_payment">
										{{ val.type_payment.name }}
									</div>
									<div v-else>
										Sin tipo de saldo
									</div>
								</td>
								<td class="text-right">
									{{ val.amount | toCurrency }}
								</td>
								<td>
									{{ getPays(val.check) | toCurrency }}
								</td>
								<td class="text-center">
									<button @click="AddTaxes(val)" v-can="'edit_tax'" v-if="getPays(val.check) == 0"
										class="btn btn-outline-primary m-1 btn-sm">
										<i class="fas fa-edit "> </i> Editar
									</button>
									<button @click="AddTaxes(val, false)" class="btn m-1 btn-outline-primary btn-sm">
										<i class="fas fa-eye "> </i> Ver más
									</button>
									<a :href="`${globalUrlFiles}${val.voucher}`" v-if="val.voucher != '' && val.voucher != null"
										target="_blank"
										class="btn btn-outline-info m-1 btn-sm">
										<i class="fa-regular fa-file-pdf"></i> Acuse
									</a>

									<a :href="`${globalUrlFiles}${val.voucher_two}`" v-if="val.voucher_two != '' && val.voucher_two != null"
										target="_blank"
										class="btn btn-outline-info m-1 btn-sm">
										<i class="fa-regular fa-file-pdf"></i> Acuse extra
									</a>


									<button v-if="getPays(val.check) == 0" type="button" @click="deleted(val.id)"
										v-can="'deleted_tax'" class="btn-outline-danger btn m-1 btn-sm ">
										<i class="fa fa-trash"></i> Eliminar
									</button>

									<button type="button" v-can="'approve_tax'" v-if="!val.check"
										@click="approvedSelected(val.id)" class="btn-outline-info bg-olive btn m-1 btn-sm">
										<i class="fa fa-check"></i> Aprobar
									</button>

									<button v-if="val.check" @click="formListPays(val.check)"
										class="btn m-1 btn-sm btn-outline-warning">
										<i class="fa-solid fa-hand-holding-dollar mr-1"> </i>
										<span>Ver pago</span>
									</button>

									<button type="button" v-can="'receipt_tax'" v-if="getPays(val.check) == val.amount"
										@click="uploadVoucher(val)" class=" btn m-1 btn-sm"
										:class="val.voucher ? 'btn-outline-info' : 'btn-outline-success'">
										<span v-if="val.voucher">
											<i class="fa-solid fa-receipt"></i> Ver acuse
										</span>
										<span v-else>
											<i class="fa-solid fa-receipt"></i> Subir acuse
										</span>
									</button>

									<button  @click="changeStatus(val)" class="btn m-1 btn-outline-info btn-sm">
										<span  v-if="val.deactivate_at" >
											<i class="fa-solid fa-boxes-packing"></i> Sacar del Archivo
										</span>
										<span v-else>
											<i class="fa-solid fa-box-archive"></i> Archivar	
										</span>
										
									</button>

								</td>
							</tr>
						</tbody>
						<tbody v-else>
							<tr>
								<td align="center" colspan="9">Sin resultados.</td>
							</tr>
						</tbody>
					</table>
					<pagination v-model="page" :records="data.total" :per-page="data.per_page" @paginate="list" />
				</div>
			</div>
		</div>

	</div>
</template>


<style scoped>
.pagination {
	margin-bottom: 0;
}
</style>
