<script>
import ModalFormComponent from '@/components/checks/ModalFormComponent.vue';
import ModalFormBill from '@/components/bill/ModalFormComponent.vue';

export default {
    components: {
        ModalFormComponent,
        ModalFormBill
    },
    data() {
        return {
            editCheck: false,
            checks: {},
        }
    },
    methods: {
        closedModal() {
            this.checks = {};
            this.$modal.hide('listChecks');
        },
        showBill(bill = {}) {
			Event.$emit('event-show-addBill', bill);
		},
        sumPays(pays) {
            let sum = 0;
            pays.forEach(function (valor) {
                sum += valor.amount;
            });
            return sum;
        },
        formData(check = {}, edit = true) {
            this.editCheck = edit;
            Event.$emit('event-show-addChecks', check);
        },
    },
    created() {
        Event.$on("event-show-listChecks", (checks) => {
            if (checks) {
                this.checks = checks;
            }
            this.$modal.show('listChecks');
        });
    },
    computed: {
        totalAmountPayable() {
            return this.checks.reduce((total, buy) => total + buy.amount_payable*1, 0);
        },
        totalPaid() {
            return this.checks.reduce((total, buy) => total + this.sumPays(buy.pay) * 1, 0);
        },
        totalRemaining() {
            return this.totalAmountPayable - this.totalPaid;
        }
    },
}
</script>
<template>
    <modal name="listChecks" :width="'80%'" :scrollable="true" :maxHeight="300" :height="'auto'">
        <ModalFormComponent :prop_edit="editCheck" />
        <ModalFormBill :prop_edit="false" />
        <button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModal()"> <i
                class="fa-solid fa-xmark"></i> </button>
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Información de pagos</h5>
        </div>
        <div class="modal-body">
            <div class="row">
                <div class="col-12">
                    <div class="table-responsive">
                        <table class="table table-bordered text-center text-sm">
                            <thead>
                                <tr>
                                    <th>ID</th>
                                    <th>Tipo</th>
                                    <th>Usuario</th>
                                    <th>Concepto</th>
                                    <th>Monto</th>
                                    <th>Pagado</th>
                                    <th>Por Pagar</th>
                                    <th>Fecha</th>
                                    <th>Tipo de saldo</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody v-if="checks">
                                <tr v-for="(buy, index) in checks" :key="index">
                                    <td>{{ buy.id }}</td>
                                    <td>
                                        <span v-if="buy.type_id == 1"> Presupuesto </span>
                                        <span v-else> Gasto recurrente </span>
                                    </td>
                                    <td>
                                        <span v-if="buy.provider"> {{ buy.provider.name }} {{ buy.provider.last_name
                                            }}</span>
                                        <span v-else>Sin usuario</span>
                                    </td>
                                    <td>
                                        <span v-if="buy.concept"> {{ buy.concept.name }}</span>
                                        <span v-else>Sin Concepto</span>
                                    </td>
                                    <td>
                                        {{ buy.amount_payable | toCurrency }}
                                    </td>
                                    <td>
                                        {{ sumPays(buy.pay) | toCurrency }}
                                    </td>
                                    <td>
                                        {{ buy.amount_payable - sumPays(buy.pay) | toCurrency }}
                                    </td>
                                    <td>
                                        {{ buy.date }}
                                    </td>

                                    <td>
                                        <span v-if="buy.type_payment"> {{ buy.type_payment.name }}</span>
                                        <span v-else>Sin Tipo de Saldo</span>
                                    </td>
                                    <td>
                                        <button @click="formData(buy, false)"
                                            class="btn m-1 btn-outline-primary btn-sm">
                                            <i class="fa-solid fa-receipt"> </i> Ver cheque
                                        </button>
                                        <button @click="showBill(buy.bill, false)"
                                            class="btn m-1 btn-outline-info btn-sm">
                                            <i class="fa-solid fa-file-invoice-dollar"> </i> Ver factura
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody v-else>
                                <tr>
                                    <td align="center" colspan="5">Sin resultados.</td>
                                </tr>
                            </tbody>
                            <tfoot v-if="checks">
                                <tr colspan="4">
                                    <td colspan="4">Total:</td>
                                    <td class="text-right">
                                        {{ totalAmountPayable | toCurrency }}
                                    </td>
                                    <td class="text-right">
                                        {{ totalPaid | toCurrency }}
                                    </td>
                                    <td class="text-right">
                                        {{ totalRemaining | toCurrency }}
                                    </td>
                                    <td colspan="3"></td>
                                </tr>                                
                            </tfoot>
                        </table>
                    </div>
                </div>
                <div class="col-md-12">
                    <hr>
                    <button type="button" @click="closedModal()" class="btn float-left btn-outline-warning">
                        <i class="fas fa-undo-alt"></i>
                        Regresar
                    </button>
                </div>
            </div>
        </div>
    </modal>
</template>