<script>
import { mapActions } from 'vuex';
import request from '@/services/request';
import Catalogue from '@/services/catalogue';

export default {
	name: "statement-account",
	data() {
		return {
			catalogue: new Catalogue(),
			search: {
				user_id: '',
				department_id: '',
				tenant_id: '',
			},
			mail:{
				email: '',
				message: ''
			},
			tenants: [],
			errors: {},
			tenant: {
				id: '',
			},
			load: false,
			sendMailB: false,
			tenantsInfo: []
		}
	},
	methods: {
		getTotalRemaining(tenant, service_id) {
			const totalPaid = this.getPayService(tenant, service_id)?.reduce((sum, item) => {
				return sum + (Number(item.pay?.amount) || 0);
			}, 0) || 0;
			return totalPaid;
		},
		sedTenant() {
			this.$modal.show('addSiganturePay');
		},
		closedModalMail() {
			this.mail.email = '';
			this.mail.message = '';
			this.$modal.hide('addSiganturePay');
		},
		getTotalDebt(tenants) {
			let totalDebt = 0;

			tenants.forEach(tenant => {
				let ids = [0];

				// Procesa services_extras
				if (tenant.services_extras) {
					tenant.services_extras.forEach(function (valor) {
						ids.push(valor.id);
					});
				}

				// Procesa services_extras_no_service
				if (tenant.services_extras_no_service) {
					tenant.services_extras_no_service.forEach(function (valor) {
						ids.push(valor.id);
					});
				}

				// Si el tenant no tiene bills_numbers, continúa con el siguiente tenant
				if (!tenant || !tenant.bills_numbers) return;

				// Suma la deuda de este tenant
				totalDebt += tenant.bills_numbers
					.filter(bill => ids.includes(bill.service_id)) // Filtra por service_id
					.reduce((sum, bill) => sum + (Number(bill.pay?.amount) || 0), 0);
			});

			return totalDebt;		
		},

		getTotalPaid(tenants) {
			let sum = 0;
			if (tenants) {
				tenants.forEach(function (tenant) {

					sum += (tenant.amount * 1)
					if (tenant.services_extras) {
						tenant.services_extras.forEach(function (valor) {
							if (valor.pivot) {
								sum += (valor.pivot.amount * 1)
							}
						});
					}

					if (tenant.services_extras_no_service) {
						tenant.services_extras_no_service.forEach(function (valor) {
							if (valor.extra == 1) {
								if (valor.negative == 1) {
									sum -= (valor.amount * 1)
								} else {
									sum += (valor.amount * 1)
								}
							}
						});
					}
				});
			}
			return sum
		},

		getRemainingBalance(tenant) {
			return this.getTotalPaid(tenant) - this.getTotalDebt(tenant);
		},
		getPayService(tenant, service_id) {
			if (tenant.bills_numbers) {
				const filteredBill = tenant.bills_numbers.filter(bill => bill.service_id === service_id);
				if (filteredBill.length > 0) {
					return filteredBill;
				} else {
					return [];
				}
			} else {
				return [];
			}
		},
		sumServices(tenant) {
			console.log(tenant);
			let sum = 0;
			if (tenant) {
				sum += (tenant.amount * 1)
				if (tenant.services_extras) {
					tenant.services_extras.forEach(function (valor) {
						if (valor.pivot) {
							if (valor.pivot.group_bill == 1) {
								sum += (valor.pivot.amount * 1)
							}
						}
					});
				}

				if (tenant.services_extras_no_service) {
					tenant.services_extras_no_service.forEach(function (valor) {
						if (valor.extra == 1) {
							if (valor.negative == 1) {
								sum -= (valor.amount * 1)
							} else {
								sum += (valor.amount * 1)
							}
						}
					});
				}
			}
			return sum

		},
		getDepartment() {
			this.catalogue.get_departamens_user(this.search.user_id);
			this.search.department_id = '';
			this.search.tenant_id = '';
			this.tenantsInfo = [];
			this.tenants = [];
		},
		async getTenants() {
			let vm = this;
			if (this.search.department_id && this.search.user_id) {
				let search = {
					user_id: this.search.user_id,
					department_id: this.search.department_id,
				};

				let _endpoint = '/tenant_user';
				try {
					let { data } = await request.get(_endpoint, search)
					if (data) {
						console.log(data);
						vm.tenants = data;
					}
				} catch (error) {
					console.log(error);
				}
			} else {
				this.tenants = [];
				this.tenantsInfo = [];
			}
		},
		getTenant() {
			if (this.search.tenant_id) {
				this.tenant.id = this.search.tenant_id;
				this.list();
			} else {
				this.tenantsInfo = [];
			}
		},
		closedModal() {
			this.tenant = {};
			this.tenantsInfo = [];

			this.search.department_id = '';
			this.search.tenant_id = '';
			this.search.user_id = '';
			this.tenants = [];
			this.errors = {};

			Event.$emit('event-load-tenants');
			this.$modal.hide('ListServicesExtrasAll');
		},
		async sendMail() {
			this.sendMailB = true;
			if (this.tenant.id) {
				let service = request;
				let _endpoint = `/tenant_statements_accounts_pdf`;
				let form = {
					id: this.tenant.id,
					email: this.mail.email,
					message: this.mail.message
				};
				try {
					let { data } = await service.post(_endpoint, form)
					if (data) {
						this.sendMailB = false;
						this.successNotification(data.message);
						this.closedModalMail();
					}
				} catch (error) {
					this.sendMailB = false;
					this.errors = {};
					if (error.response) {
						this.errors = await this.errorNotification(error);
					}
					console.log(error);
				}
			}
		},
		async list() {
			let vm = this;
			this.tenantsInfo = [];
			this.load = true;
			if (this.tenant.id) {
				this.tenantsInfo = [];
				let service = request;
				// let _endpoint = this.tenant.id + '/services_extras_unavailable';
				let _endpoint = `/tenant_statements_accounts?id=${this.tenant.id}`;
				try {
					let { data } = await service.get(_endpoint)
					if (data) {
						console.log('data');
						console.log(data);
						vm.tenantsInfo = data;
						if(data.length > 0){
							vm.mail.email = data[0].user.email;
						}
						this.load = false;
					}
				} catch (error) {
					console.log(error);
					this.load = false;

				}
			}
		},
		...mapActions({
			errorNotification: 'alert/error',
			successNotification: 'alert/success',
		}),
	},
	mounted() {
		this.catalogue.get_users_profile(7);
	},
	created() {
		Event.$on("event-list-extra-services-all", () => {
			this.$modal.show('ListServicesExtrasAll');
		});
	},
	computed: {

	},
	watch: {


	}
}
</script>

<template>
	<div class="col-12">
		<div class="card card-outline card-navy">
			<div class="card-header">
				<h3 class="card-title">Estado de cuentas</h3>
				<div class="card-tools">

				</div>
			</div>
			<div class="card-body">
				<div class="row">
					<div class="col-md-4">
						<label>Cliente :</label>
						<select class="form-control" v-model="search.user_id" @change="getDepartment()">
							<option value="">Sin cliente</option>
							<option :value="user.id" v-for="(user, user_index) in catalogue.users_profile"
								:key="user_index">{{ user.name }} {{ user.last_name }}</option>
						</select>
					</div>
					<div class="col-md-4">
						<label> Indiviso :</label>
						<select class="form-control" v-model="search.department_id" @change="getTenants()">
							<option value="">Sin indiviso </option>
							<option :value="company.id" v-for="(company, index) in catalogue.departamens_user"
								:key="index">
								{{ company.name }}
							</option>
						</select>
					</div>
					<div class="col-md-4">
						<label>Periodo :</label>
						<select class="form-control" v-model="search.tenant_id" @change="getTenant()">
							<option value="">Sin periodo</option>
							<option v-if="tenants.id" :value="tenants.id">
								{{ tenants.star_date | toDateTime }}
							</option>
							<option :value="tenant.id" v-for="(tenant, index) in tenants.tenants" :key="index">
								{{ tenant.star_date | toDateTime }}
							</option>
						</select>
					</div>
					<div class="col-md-12">
						<hr>
					</div>
				</div>
				<br>
				<div class="row">
					<div class="col-md-12 table-responsive">
						<table class="table table-bordered tbl-pays">
							<thead>
								<tr>
									<th>Fecha cargo</th>
									<th>Concepto</th>
									<th>Folio</th>
									<th>Abono</th>
									<th>Cargo</th>
									<th>Saldo</th>
								</tr>
							</thead>
							<tbody v-if="load">
								<tr>
									<td align="center" colspan="7">
										<i class="fa-solid fa-circle-notch fa-spin fa-3x mb-1"></i>
										<h5>Cargando</h5>
									</td>
								</tr>
							</tbody>
							<tbody v-else-if="tenantsInfo.length > 0">
								<tr v-for="(tenant, index) in tenantsInfo" :key="'tenant-' + index">
									<td colspan="6" class="p-0">
										<table class="table  tbl-pays table-bordered text-center text-sm m-0">
											<tr>
												<td>
													{{ tenant.star_date | toDateTime }}
												</td>
												<td>Renta</td>
												<td>
													<span v-if="getPayService(tenant, 0)?.length">
														<span v-for="(item, idx) in getPayService(tenant, 0)"
															:key="'folio-' + idx">
															<span v-if="item.pay">
																{{ item.folio }}
															</span>
															<span v-else>
																S/N
															</span><br>
														</span>
													</span>
													<span v-else>S/N</span>
												</td>
												<td class="text-right">
													<span v-if="getPayService(tenant, 0)?.length">
														<span v-for="(item, idx) in getPayService(tenant, 0)"
															:key="'amount-' + idx">
															<span v-if="item.pay">
																{{ item.pay.amount | toCurrency }}
															</span>
															<span v-else>$ 0.00</span>
														</span>
													</span>
													<span v-else>$ 0.00</span>
												</td>
												<td class="text-right">{{ sumServices(tenant) | toCurrency }}</td>
												<td class="text-right">{{ (sumServices(tenant) -
													getTotalRemaining(tenant, 0)) | toCurrency
												}}</td>
											</tr>
											<tr v-show="services.pivot.group_bill == 0"
												v-for="(services, index) in tenant.services_extras" :key="index">
												<td>{{ tenant.star_date | toDateTime }}</td>
												<td>{{ services.name }}</td>
												<td>
													<span v-if="getPayService(tenant, services.id)?.length">
														<span
															v-for="(item, index) in getPayService(tenant, services.id)"
															:key="index">
															<span v-if="item.pay">
																{{ item.folio }}
															</span>
															<span v-else>
																S/N
															</span>
														</span>
													</span>
													<span v-else>
														S/N
													</span>
												</td>
												<td class="text-right">
													<span v-if="getPayService(tenant, services.id)?.length">
														<span
															v-for="(item, index) in getPayService(tenant, services.id)"
															:key="index">
															<span v-if="item.pay">
																{{ item.pay.amount | toCurrency }}
															</span>
															<span v-else>$ 0.00</span>
														</span>
													</span>
													<span v-else>
														$ 0.00
													</span>
												</td>
												<td class="text-right">{{ services.pivot.amount | toCurrency }}</td>
												<td class="text-right">
													{{ services.pivot.amount - getTotalRemaining(tenant,
														services.id) | toCurrency }}
												</td>
											</tr>
										</table>
									</td>
								</tr>

							</tbody>
							<tbody v-else>
								<tr>
									<td align="center" colspan="6">Sin resultados.</td>
								</tr>
							</tbody>
							<tfoot>
								<tr>
									<td colspan="5">
										Saldo de contrato al dia de hoy:
									</td>
									<td class="text-right">
										{{ getRemainingBalance(tenantsInfo) | toCurrency }}
									</td>
								</tr>
							</tfoot>
						</table>
					</div>
					<div class="col-md-12 text-right" v-if="tenantsInfo.length == 0">
						<hr>
					</div>
					<div class="col-md-12 text-right" v-if="tenantsInfo.length > 0">
						<button type="button" class="btn btn-outline-success" @click="sedTenant()">
							<i class="fa fa-envelope" aria-hidden="true"></i> Enviar por correo
						</button>
					</div>
				</div>
			</div>
		</div>

		<modal name="addSiganturePay" :width="'80%'" :maxHeight="380" :adaptive="true" :maxWidth="800" :height="'auto'">
			<button type="button" class="btn btn-danger btn-sm btn-closed" @click="closedModalMail()"> <i
					class="fa-solid fa-xmark"></i>
			</button>
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">Firmar de pago</h5>
			</div>
			<div class="modal-body">
                <div class="row">
                    <div class="col-md-12">
                        <label>Correos electrónicos</label>
                        <input type="text" class="form-control" v-model="mail.email">
                        <div v-if="errors && errors.email" class="text-danger">{{ errors.email[0] }}</div>
                    </div>
                    <div class="col-md-12">
                        <label>Mensaje</label>
                        <textarea type="text" class="form-control" v-model="mail.message"></textarea>
                        <div v-if="errors && errors.message" class="text-danger">{{ errors.message[0] }}</div>
					</div>
					<div class="col-md-12">
						<hr>
					</div>
					<div class="col-md-12">
						<button @click="sendMail()" :disables="sendMailB" type="button" class="btn float-right btn-outline-success">
							<span v-if="sendMailB">
								<i class="fa-solid fa-circle-notch fa-spin"></i> Enviando
							</span>
							<span v-else>
								<i class="fas fa-save"></i> Enviar
							</span>
						</button>
						<button type="button" @click="closedModalMail()" class="btn float-left btn-outline-warning">
							<i class="fas fa-undo-alt"></i>
							Regresar
						</button>
					</div>
				</div>
			</div>
		</modal>


	</div>
</template>


<style scoped>
.pagination {
	margin-bottom: 0;
}

.tbl-pays {
	table-layout: fixed;
	min-width: 900px;
	width: 100%;
}
</style>
